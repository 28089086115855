import React from "react";

const MaintenanceMode = () => {
  return (
    <div
      ClassName="page-container"
      style={{ textAlign: "center", padding: "20px" }}
    >
      <h1>Under Maintenance</h1>
      <p>
        We're sorry for the inconvenience, but the site is currently undergoing
        maintenance. Please check back later.
      </p>
    </div>
  );
};

export default MaintenanceMode;
