import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../components/modals/CustomModal";

import { useAuth } from "../../contexts/AuthContext";

function LoginPage() {
  const navigate = useNavigate();

  const emailRef = useRef();
  const passwordRef = useRef();

  const { login } = useAuth();

  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    description: "",
    buttons: [],
  });

   const openModal = (title, description, buttons = []) => {
     setModalContent({
       title,
       description,
       buttons,
     });
     setModalIsOpen(true);
   };

   const closeModal = () => {
     setModalIsOpen(false);
   };

   const navigateToResetPasswordPage = () => navigate("/forgot-password");
   const navigateToSignUpPage = () => navigate("/signup")

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setModalContent({
        title: "",
        description: "",
        buttons: [],
      });
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
    } catch (error) {
      // console.log("login error", error.message);
      if (error?.code === "auth/invalid-login-credentials") {
         openModal("Login Error", "Invalid email or password", [
           { text: "Close", onClick: closeModal },
         ]);
      } else {
        openModal("Login Error", "Failed to Login", [
          { text: "Close", onClick: closeModal },
        ]);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div>
        <h1 className="boost_8">Hello Again!</h1>
        <p className="boost_9">Welcome Back</p>
        <form className="boost_11" onSubmit={handleSubmit}>
          <div>
            <p htmlFor="" className="boost_12">
              Email
            </p>
            <div className="boost_13">
              <svg
                className="message"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.5 5.25L2.25 4.5H21.75L22.5 5.25V18.75L21.75 19.5H2.25L1.5 18.75V5.25ZM3 6.8025V18H21V6.804L12.465 13.35H11.55L3 6.8025ZM19.545 6H4.455L12 11.8035L19.545 6Z"
                  fill="#62C57E"
                />
              </svg>
              <input
                type="email"
                ref={emailRef}
                className="boost_14"
                placeholder="example@email.com"
                required
              />
            </div>
          </div>
          <div>
            <p htmlFor="" className="boost_12">
              Password
            </p>
            <div className="boost_13">
              <svg
                className="message"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 12C20 10.897 19.103 10 18 10H17V7C17 4.243 14.757 2 12 2C9.243 2 7 4.243 7 7V10H6C4.897 10 4 10.897 4 12V20C4 21.103 4.897 22 6 22H18C19.103 22 20 21.103 20 20V12ZM9 7C9 5.346 10.346 4 12 4C13.654 4 15 5.346 15 7V10H9V7Z"
                  fill="#62C57E"
                />
              </svg>
              <input
                type="password"
                ref={passwordRef}
                className="boost_14"
                placeholder="********"
                required
              />
            </div>
          </div>
          <button type="submit" disabled={loading} className="loginbtn">
            Login
          </button>
        </form>
        <hr className="hr" />
        <p className="register">
          Forgot Your Password?
          <span className="now" onClick={navigateToResetPasswordPage}>
            Reset
          </span>
        </p>
      </div>
      <CustomModal
        isOpen={modalIsOpen}
        title={modalContent.title}
        description={modalContent.description}
        buttons={modalContent.buttons}
        closeModal={closeModal}
      />
    </>
  );
}

export default LoginPage;