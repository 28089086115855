import React, {useEffect, useState} from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { PhoneNumberUtil } from "google-libphonenumber";

import { useAuth } from '../../contexts/AuthContext';
import { usersCollectionRef } from '../../firebase/firestore/collections';
import { getLocalApiLinks } from '../../utils/localStorage/apiLinks';
import { setDoc, doc, collection, getDocs, where, query } from 'firebase/firestore';

function SignupPage() {
  const navigate = useNavigate();
  const { signup } = useAuth();

  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    username: "",
    profileImage: "",
    password: "",
    confirmPassword: "",
  });
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      setLoading(true);
      const docsSnap = await getDocs(usersCollectionRef);
      let users = [];
      docsSnap.docs.map((doc) => users.push({ ...doc.data(), id: doc.id }));
      setUsersList(users);
    } catch (error) {
      console.log("Error getting all users", error);
    } finally {
      setLoading(false);
    }
  };

  const [loading, setLoading] = useState(false);
  const [apis, setApis] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    fetchApis();
  }, []);


  const sendDataToSheet = async (firstName, lastName, Email, PhoneNumber) => {
    const Name = firstName + " " + lastName;
    try {
      const body = {
        Name,
        Email,
        PhoneNumber,
      };
      let response = await fetch("https://sheet.best/api/sheets/deb59ab8-5fc5-46b7-adfa-3d8aede96ccc", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      response = await response.json();
      return response;
    }catch (error) {
      console.error("Error sending data to sheet:", error);
    }
  }


  const fetchApis = async () => {
    try {
      const apis = await getLocalApiLinks();
      setApis(apis);
    } catch (error) {
      console.error("Error fetching api links:", error);
    }
  };

  const handleUserDetails = (e) => {
    setUserDetails((prev) => {
      return {
        ...prev,
        [e.target.id]: e.target.value,
      };
    });
  };

  async function isUsernameTaken(username) {
    const snapshot = await getDocs(
      query(usersCollectionRef, where("username", "==", username))
    );
    return snapshot.docs.length > 0;
  }

  async function validateForm() {
    setError("");

    function validateRequiredField(fieldName, fieldValue) {
      if (!fieldValue) {
        setError(`${fieldName} is required`);
        return false;
      }
      return true;
    }

    function validateEmail(email) {
      if (!/^\S+@\S+\.\S+$/.test(email)) {
        setError("Invalid email format");
        return false;
      }
      return true;
    }

    const validatePhoneNumber = (phoneNumber) => {
      const phoneUtil = PhoneNumberUtil.getInstance();
      try {
        const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(phoneNumber);
        const isValid = phoneUtil.isValidNumber(parsedPhoneNumber);
        return isValid;
      } catch (error) {
        setError("Please enter a valid phone number");
        return false;
      }
    };

    function validateUsername(username) {
      if (!username) {
        setError("Username is required");
        return false;
      }
      if (!/^[a-z]{1,20}$/.test(username)) {
        setError(
          "Invalid username format. It should be all lowercase and max 20 characters."
        );
        return false;
      }
      return true;
    }

    async function validateUsernameAvailability(username) {
      const isTaken = await isUsernameTaken(username);
      if (isTaken) {
        setError("Username is already taken");
        return false;
      }
      return true;
    }

    const isFirstNameValid = validateRequiredField(
      "First Name",
      userDetails.firstName
    );
    const isLastNameValid = validateRequiredField(
      "Last Name",
      userDetails.lastName
    );
    const isEmailValid =
      validateRequiredField("Email", userDetails.email) &&
      validateEmail(userDetails.email);
    const isPhoneNumberValid =
      validateRequiredField("Phone Number", userDetails.phoneNumber) &&
      validatePhoneNumber(userDetails.phoneNumber);
    const isUsernameValid = validateUsername(userDetails.username);
    const isUsernameAvailable = await validateUsernameAvailability(
      userDetails.username
    );
    const isPasswordValid = validateRequiredField(
      "Password",
      userDetails.password
    );
    const isConfirmPasswordValid = validateRequiredField(
      "Confirm Password",
      userDetails.confirmPassword
    );

    return (
      isFirstNameValid &&
      isLastNameValid &&
      isEmailValid &&
      isPhoneNumberValid &&
      isUsernameValid &&
      isPasswordValid &&
      isConfirmPasswordValid &&
      isUsernameAvailable
    );
  }

  async function createStripeCustomer(uid) {

    try {
      const body = {
        name: userDetails.firstName + " " + userDetails.lastName,
        email: userDetails.email,
        user_id: uid,
      };

      let response = await fetch(
        apis.stripe_create_customer,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      response = await response.json();

      //  console.log("create customer", response);
      return response;
    } catch (error) {
      console.log("error creating customer", error);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    // if it failes validation then return
    for (let i = 0; i < usersList.length; i++){
      if (usersList[i].username === userDetails.username) {
        alert("Username already exists")
        setError("Username already exists");
        return;
      }
    }
    if (!validateForm()) return;

    if (userDetails.password !== userDetails.confirmPassword) {
      return setError("Passwords do not match");
    }


    try {
      setError("");
      setLoading(true);
      const response = await signup(userDetails.email, userDetails.password);
      if (response.user.uid) {
        const userDocRef = doc(usersCollectionRef, response.user.uid);
        const billingDetailsCollectionRef = collection(
          userDocRef,
          "BillingDetails"
        );

        //sendDataToSheet(userDetails.firstName, userDetails.lastName, userDetails.email, userDetails.phoneNumber);

        let userData = {
          first_name: userDetails.firstName,
          last_name: userDetails.lastName,
          username: userDetails.username,
          email: userDetails.email,
          phone_number: userDetails.phoneNumber,
          isAdmin: false,
          balance: 0.0,
          isCompany: false,
          customer_id: "",
          date_created: Date.now(),
        };
        const billingDetailsData = {
          addressLine1: "",
          addressLine2: "",
          city: "",
          country: "",
          region: "",
          zipCode: "",
          companyName: "",
          companyRole: "",
          taxId: "",
        };
        const createCustomerResponse = await createStripeCustomer(
          response.user.uid
        );
        if (!createCustomerResponse.error) {
          userData["customer_id"] = createCustomerResponse.data.customer_id;
        }

        await setDoc(userDocRef, userData, { merge: true });
        await setDoc(
          doc(billingDetailsCollectionRef, "data"),
          billingDetailsData
        );

        setUserDetails({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          username: "",
          profileImage: "",
          password: "",
          confirmPassword: "",
        });
        setAcceptedTerms(false);

        navigate("/user/order");
      }
    } catch (error) {
      console.log("signup error", error.message);
      setError("Failed to create an account");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <h1 className="boost_8">Sign Up!</h1>
      {/* Add some modal type of component here to show the error message */}
      <p className='select_1'>{error}</p>
      <form className="boost_11" onSubmit={handleSubmit}>
        <div className="boost_13">
          <svg
            className="message"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4ZM14 8C14 6.9 13.1 6 12 6C10.9 6 10 6.9 10 8C10 9.1 10.9 10 12 10C13.1 10 14 9.1 14 8ZM18 18C17.8 17.29 14.7 16 12 16C9.31 16 6.23 17.28 6 18H18ZM4 18C4 15.34 9.33 14 12 14C14.67 14 20 15.34 20 18V20H4V18Z"
              fill="#62C57E"
            />
          </svg>
          <input
            type="text"
            id="firstName"
            className="boost_14"
            placeholder="First Name"
            onChange={handleUserDetails}
            required
          />
        </div>
        <br />
        <div className="boost_13">
          <svg
            className="message"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4ZM14 8C14 6.9 13.1 6 12 6C10.9 6 10 6.9 10 8C10 9.1 10.9 10 12 10C13.1 10 14 9.1 14 8ZM18 18C17.8 17.29 14.7 16 12 16C9.31 16 6.23 17.28 6 18H18ZM4 18C4 15.34 9.33 14 12 14C14.67 14 20 15.34 20 18V20H4V18Z"
              fill="#62C57E"
            />
          </svg>
          <input
            type="text"
            id="lastName"
            className="boost_14"
            placeholder="Last Name"
            onChange={handleUserDetails}
            required
          />
        </div>
        <br />
        <div className="boost_13">
          <svg
            className="message"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.5 5.25L2.25 4.5H21.75L22.5 5.25V18.75L21.75 19.5H2.25L1.5 18.75V5.25ZM3 6.8025V18H21V6.804L12.465 13.35H11.55L3 6.8025ZM19.545 6H4.455L12 11.8035L19.545 6Z"
              fill="#62C57E"
            />
          </svg>
          <input
            type="email"
            id="email"
            className="boost_14"
            placeholder="Email Address"
            onChange={handleUserDetails}
            required
          />
        </div>
        <br />
        <div className="boost_13">
          <svg
            className="message"
            width={24}
            height={24}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m 8 1 c -1.65625 0 -3 1.34375 -3 3 s 1.34375 3 3 3 s 3 -1.34375 3 -3 s -1.34375 -3 -3 -3 z m -1.5 7 c -2.492188 0 -4.5 2.007812 -4.5 4.5 v 0.5 c 0 1.109375 0.890625 2 2 2 h 8 c 1.109375 0 2 -0.890625 2 -2 v -0.5 c 0 -2.492188 -2.007812 -4.5 -4.5 -4.5 z m 0 0"
              fill="#62C57E"
            />
          </svg>
          <input
            type="text"
            id="username"
            className="boost_14"
            placeholder="Username"
            onChange={handleUserDetails}
            required
          />
        </div>
        <br />
        <div className="boost_13">
          <svg
            className="message"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 5.5C3 14.0604 9.93959 21 18.5 21C18.8862 21 19.2691 20.9859 19.6483 20.9581C20.0834 20.9262 20.3009 20.9103 20.499 20.7963C20.663 20.7019 20.8185 20.5345 20.9007 20.364C21 20.1582 21 19.9181 21 19.438V16.6207C21 16.2169 21 16.015 20.9335 15.842C20.8749 15.6891 20.7795 15.553 20.6559 15.4456C20.516 15.324 20.3262 15.255 19.9468 15.117L16.74 13.9509C16.2985 13.7904 16.0777 13.7101 15.8683 13.7237C15.6836 13.7357 15.5059 13.7988 15.3549 13.9058C15.1837 14.0271 15.0629 14.2285 14.8212 14.6314L14 16C11.3501 14.7999 9.2019 12.6489 8 10L9.36863 9.17882C9.77145 8.93713 9.97286 8.81628 10.0942 8.64506C10.2012 8.49408 10.2643 8.31637 10.2763 8.1317C10.2899 7.92227 10.2096 7.70153 10.0491 7.26005L8.88299 4.05321C8.745 3.67376 8.67601 3.48403 8.55442 3.3441C8.44701 3.22049 8.31089 3.12515 8.15802 3.06645C7.98496 3 7.78308 3 7.37932 3H4.56201C4.08188 3 3.84181 3 3.63598 3.09925C3.4655 3.18146 3.29814 3.33701 3.2037 3.50103C3.08968 3.69907 3.07375 3.91662 3.04189 4.35173C3.01413 4.73086 3 5.11378 3 5.5Z"
              stroke="#62C57E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <input
            type="tel"
            id="phoneNumber"
            className="boost_14"
            placeholder="Phone Number"
            onChange={handleUserDetails}
            required
          />
        </div>
        <br />
        <div className="boost_13">
          <svg
            className="message"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#62C57E"
              fill-rule="evenodd"
              d="M19.775 2a2.225 2.225 0 0 1 1.566 3.791l-8.577 8.588c-.011.011-.033.022-.044.033a5.427 5.427 0 0 1 .389 2.033 5.555 5.555 0 1 1-5.554-5.555 5.428 5.428 0 0 1 2.033.389c.011-.011.022-.033.033-.044l3.355-3.344-1.766-1.767a1.115 1.115 0 0 1 1.577-1.578l.389.389a1.148 1.148 0 0 1 .644-.644l-.388-.391a1.115 1.115 0 0 1 1.577-1.578l.391.392a1.125 1.125 0 0 1 .255-.389 1.12 1.12 0 0 1 1.577 0l.655.655.322-.322A2.194 2.194 0 0 1 19.775 2M7.555 18.667a2.222 2.222 0 1 0-2.222-2.222 2.228 2.228 0 0 0 2.222 2.222M19.775 0a4.173 4.173 0 0 0-1.8.4 3.142 3.142 0 0 0-2.643-.2 3.115 3.115 0 0 0-4.124 2.12A3.115 3.115 0 0 0 9.8 7.539l.35.35L9 9.029A7.548 7.548 0 1 0 14.971 15l7.785-7.8a4.193 4.193 0 0 0-.01-5.969A4.157 4.157 0 0 0 19.775 0z"
            />
          </svg>
          <input
            type="password"
            id="password"
            className="boost_14"
            placeholder="Password"
            onChange={handleUserDetails}
            required
          />
        </div>
        <br />
        <div className="boost_13">
          <svg
            className="message"
            width={24}
            height={24}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.408 3.412a1.974 1.974 0 0 0 0-2.82 1.973 1.973 0 0 0-2.819 0l-.29.29-.59-.59a1.009 1.009 0 0 0-1.65.35l-.35-.35a1.004 1.004 0 1 0-1.42 1.42l.35.35a1.033 1.033 0 0 0-.58.58l-.35-.35a1.004 1.004 0 0 0-1.42 1.42L9.879 5.3l-3.02 3.01c-.01.01-.02.03-.03.04A4.885 4.885 0 0 0 5 8a5 5 0 1 0 5 5 4.885 4.885 0 0 0-.35-1.83c.01-.01.03-.02.04-.03l7.718-7.728zM5 15a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
              fill="#62C57E"
              fill-rule="evenodd"
            />
          </svg>
          <input
            type="password"
            id="confirmPassword"
            className="boost_14"
            placeholder="Confirm Password"
            onChange={handleUserDetails}
            required
          />
        </div>
        <div className="check_main">
          <input
            type="checkbox"
            id="terms"
            className="check_1"
            checked={acceptedTerms}
            onChange={() => setAcceptedTerms(!acceptedTerms)}
            required
          />
          <label htmlFor="terms" className="check_2">
            I agree to the <Link to="/terms">Terms &amp; Conditions</Link>
          </label>
        </div>
        <button type="submit" className="loginbtn">
          Signup
        </button>
      </form>
    </div>
  );
}

export default SignupPage;