import React, { useEffect, useState } from 'react';
import { query, orderBy, limit, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { transactionsCollectionRef } from '../../firebase/firestore/collections';
import { doc, updateDoc } from 'firebase/firestore';
import { getLocalApiLinks } from '../../utils/localStorage/apiLinks';

async function updateTransactionStatus(transactionId, newStatus) {
  const transactionDocRef = doc(transactionsCollectionRef, transactionId);
  await updateDoc(transactionDocRef, { status: newStatus });
}

function PaymentHistoryTable({ data, onRefund }) {
  const handleRefund = async (payment) => {
    if (window.confirm("Are you sure you want to refund this payment?")) {
      onRefund(payment); // Call the onRefund function passed from the parent component
    }
  };

  if (!data.length > 0) {
    return <p>No Data Available</p>;
  }

  return (
    <div className='table-container'>
      <table className='custom-table'>
      <thead>
        <tr>
          <th>ID</th>
          <th>User</th>
          <th>Method</th>
          <th>Payment ID</th>
          <th>Status</th>
          <th>Date</th>
          <th>Amount</th>
          <th>Refund</th>
        </tr>
      </thead>
      <tbody>
        {data.map((payment, index) => (
          <tr key={index}>
            <td data-label="ID">{payment.id}</td>
            <td data-label="User">
              <Link to={`/admin/edit-user/${payment.user}`}>
                {payment.username}
              </Link>
            </td>
            <td data-label="Payment Method">{payment.paymentMethod}</td>
            <td data-label="Payment ID">{payment.paymentID}</td>
            <td data-label="Status">{payment.status}</td>
            <td data-label="Date">{payment.date}</td>
            <td data-label="Amount">${payment.amount}</td>
            <td data-label="Refund">
              {payment.status !== "Refunded" && (
                <button onClick={() => handleRefund(payment)}>
                  Refund Gateway
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  );
}

function TransactionsPage() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apis, setApis] = useState({});

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setLoading(true);
        const transactionsQuery = query(
          transactionsCollectionRef,
          orderBy("date", "desc"),
          limit(10)
        );
        const querySnapshot = await getDocs(transactionsQuery);

        if (querySnapshot.empty) {
          setTransactions([]);
        } else {
          const fetchedTransactions = querySnapshot.docs.map((doc) => {
            const transactionData = doc.data();
            const date = transactionData.date.toDate();
            const formattedDate = date.toISOString().split("T")[0];

            return {
              id: doc.id,
              user: transactionData.userid,
              username: transactionData.username,
              paymentMethod: transactionData.method,
              paymentID: transactionData.paymentID,
              status: transactionData.status,
              date: formattedDate,
              amount: transactionData.amount,
            };
          });

          setTransactions(fetchedTransactions);
        }
      } catch (error) {
        console.log("Error fetching transactions: ", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchApis = async () => {
      try {
        const apiLinks = await getLocalApiLinks();

        setApis(apiLinks);
      } catch (error) {
        console.error("Error fetching api links:", error);
      }
    };

    fetchTransactions();
    fetchApis();
  }, []);

  const processRefund = async (payment) => {
    try {
      const response = await fetch(apis.stripe_process_refund, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ payment_intent_id: payment.paymentID }),
      });

      const data = await response.json();

      if (data.error) {
        throw new Error(data.message);
      } else {
        // Update the local state
        const updatedTransactions = transactions.map((t) => {
          if (t.id === payment.id) {
            return { ...t, status: 'Refunded' };
          }
          return t;
        });
        setTransactions(updatedTransactions);

        // Optionally, update the status in Firebase
        // Replace <YOUR_FIREBASE_UPDATE_FUNCTION> with your actual Firebase function
        await updateTransactionStatus(payment.id, 'Refunded');
      }
    } catch (error) {
      console.log('Refund error:', error);
      alert('Refund failed: ' + error.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (transactions.length === 0) {
    return (
      <div className="page-container">
        <h1>Transactions</h1>
        <p>No transactions found.</p>
      </div>
    );
  }

  return (
    <div className="page-container">
      <h1>Transactions</h1>
      <PaymentHistoryTable data={transactions} onRefund={processRefund} />
    </div>
  );
}

export default TransactionsPage;