import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { prServicesCatsDataCollectionRef } from '../../../firebase/firestore/collections';

function EditPRServiceCategoryPage() {
  const [category, setCategory] = useState({ name: '', displayStatus: false });
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getCategory = async () => {
      setLoading(true);
      const docRef = doc(prServicesCatsDataCollectionRef, id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setCategory({ ...docSnap.data(), id: docSnap.id });
      } else {
        console.log('No such category!');
      }
      setLoading(false);
    };

    getCategory();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const docRef = doc(prServicesCatsDataCollectionRef, id);
    try {
      await updateDoc(docRef, {
        name: category.name,
        displayStatus: category.displayStatus,
      });
      navigate('/admin/pr-services/categories');
    } catch (error) {
      console.log('Error updating category:', error);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setCategory((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-container">
      <h2>Edit PR Service Category: {category.name}</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Category Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={category.name}
          onChange={handleChange}
          required
        />

        <label htmlFor="displayStatus">
          Display Status:
          <input
            type="checkbox"
            id="displayStatus"
            name="displayStatus"
            checked={category.displayStatus}
            onChange={handleChange}
          />
        </label>

        <button type="submit" disabled={loading}>
          Update Category
        </button>
      </form>
    </div>
  );
}

export default EditPRServiceCategoryPage;
