import React, {useState} from 'react'
import { Outlet } from 'react-router-dom';
import SideBar from "../navbars/LeftNavBar";

function DynamicPagesLayout() {
  const [showMenu, setShowMenu] = useState(true);
  
  return (
    <div className="main">
      <SideBar isShowMenu={showMenu} />
      <Outlet />
    </div>
  );
}

export default DynamicPagesLayout
