import { getDoc } from "firebase/firestore";
import { apiKeysDocumentRef } from "../../firebase/firestore/collections";

export const getLocalApiKeys = async () => {
  try {
    const apiKeysSnapshot = await getDoc(apiKeysDocumentRef);
    const data = apiKeysSnapshot.data();
    
    return data;
  } catch (error) {
    console.error("Error getting local api keys:", error);
    return null;
  }
};
