import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import CustomModal from '../../../components/modals/CustomModal';
// icon
import { BsFillCheckCircleFill } from "react-icons/bs";
// stripe
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
// firebase
import { addDoc, collection, doc, getDocs, setDoc, increment, updateDoc, getDoc, query, where, orderBy, limit } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { auth, storage } from '../../../firebase/firebase';
import {
  ordersCollectionRef,
  prServicesCatsDataCollectionRef,
  prServicesDataCollectionRef,
  transactionsCollectionRef,
  usersCollectionRef,
  saleDocumentRef
} from "../../../firebase/firestore/collections";
// context
import { useAuth } from '../../../contexts/AuthContext';
// utils
import moment from 'moment';
import { getLocalSiteSettings } from '../../../utils/localStorage/siteSettings';
import { getLocalApiLinks } from '../../../utils/localStorage/apiLinks';
import { getLocalApiKeys } from '../../../utils/localStorage/apiKeys';
import { sendEmailVerification } from 'firebase/auth';


const SavedCardsTable = ({ savedCards = [], selectedCard, onSelectCard }) => {
  return (
    <table className=' table-container custom-table'>
      {/* <thead>
        <tr>
          <th>Brand</th>
          <th>Card Number</th>
          <th>Expiry Date</th>
          <th></th>
        </tr>
      </thead> */}
      <tbody>
        {savedCards.map((card) => (
          <tr
            key={card.paymentMethodId}
            style={{cursor: 'pointer'}}
            onClick={() => onSelectCard(card.paymentMethodId)}
          >
            <td>{card.brand}</td>
            <td>**** {card.last4}</td>
            <td>{`${card.exp_month}/${card.exp_year}`}</td>
            <td>
              <BsFillCheckCircleFill
                color={
                  selectedCard === card.paymentMethodId ? "#19c37d" : "#ccc"
                }
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const Questionnaire = ({questions, answers, handleAnswerChange}) => {
  return (
    <div>
      {questions.map((question) => (
        <div key={question.id} className="form-group">
          <p className="select_1">{question.text}</p>
          <textarea
            rows={30}
            className="select_field form-textarea"
            value={answers[question.id] || ''}
            onChange={(e) => handleAnswerChange(question.id, e.target.value)}
            required
            />
        </div>
      ))}
    </div>
  );
};

function OrderForm() {
  const stripe = useStripe();
  const elements = useElements();

  const { currentUser, updateCurrentUserData } = useAuth();

  const navigate = useNavigate();

  const [categoryList, setCategoryList] = useState([]);
  const [publicationList, setPublicationList] = useState([]);
  const [filteredPublications, setFilteredPublications] = useState([]);
  const [saleData, setSaleData] = useState(null);
  const [showSale, setShowSale] = useState(false);

  const [category, setCategory] = useState("");
  const [publication, setPublication] = useState("");
  const [amount, setAmount] = useState("");
  const [image, setImage] = useState(null);
  const [articleWritingRequested, setArticleWritingRequested] = useState(false);
  const [docLink, setDocLink] = useState("");
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [deductFromAccount, setDeductFromAccount] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const [orders, setOrders] = useState([]);
  const [savedCards, setSavedCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState();
  const [selectedFileName, setSelectedFileName] = useState("");
  const [billingDetailsValidation, setBillingDetailsValidation] =
    useState(true);

  const [apis, setApis] = useState({});
  const [apiKeys, setApiKeys] = useState({});
  const [slackNotification, setSlackNotification] = useState(false);
  const [professionalWriterStatus, setProfessionalWriterStatus] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    description: "",
    buttons: [],
  });

  useEffect(() => {
    getServices();
    validateBillingDetails();
    getOngoingSale();
    getOrderHistory();
  }, []);

  useEffect(() => {
    if (Object.keys(apis).length === 0) return;
    getSavedCards();
  }, [apis]);

  useEffect(() => {
    const fetchProfessionalWriterStatus = async () => {
      try {
        const siteSettings = getLocalSiteSettings();
        const apiLinks = await getLocalApiLinks();
        const apiKeys = await getLocalApiKeys();

        setProfessionalWriterStatus(siteSettings.professional_writer);
        setSlackNotification(siteSettings.slack_notification);
        setApis(apiLinks);
        setApiKeys(apiKeys);
      } catch (error) {
        console.error("Error fetching site settings mode:", error);
      }
    };

    const fetchQuestions = async () => {
      try {
        const response = await fetch("/questions.json");
        if (!response.ok) {
          throw new Error("Failed to fetch maintenance mode");
        }
        const questionsData = await response.json();
        setQuestions(questionsData);
      } catch (error) {
        console.error("Error fetching maintenance mode:", error);
      }
    };

    fetchProfessionalWriterStatus();
    fetchQuestions();
  }, []);

  useEffect(() => {
    handlePublications();
  }, [category]);

  useEffect(() => {
    if (publication === "") return;
    handleAmount();
  }, [publication]);

  const openModal = (title, description, buttons = []) => {
    setModalContent({
      title,
      description,
      buttons,
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const navigateToSupportPage = () => navigate("/user/support");

  const handleAnswerChange = (questionId, answer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  };

  const getServices = async () => {
    try {
      const categoriesDocSnap = await getDocs(prServicesCatsDataCollectionRef);
      const sitesDocSnap = await getDocs(prServicesDataCollectionRef);

      let categories = [];
      let publications = [];

      categoriesDocSnap.docs.map((doc) => categories.push(doc.data()));
      sitesDocSnap.docs.map((doc) => publications.push(doc.data()));

      setCategoryList(categories);
      setPublicationList(publications);
    } catch (error) {
      console.log("get services error", error);
    }
  };

  const getOngoingSale = async () => {
    try {
      const snapshot = await getDoc(saleDocumentRef);
      const data = snapshot.data();
      const { price, percent, publication, sale_price, sale_type } = data;

      if (
        (price &&
          price !== "0" &&
          percent &&
          percent !== "0%" &&
          publication &&
          publication !== "" &&
          sale_type &&
          sale_type !== "") ||
        (sale_price && sale_price !== "0")
      ) {
        setSaleData({
          price: price,
          percent: percent,
          publication: publication,
          salePrice: sale_price,
          saleType: sale_type,
        });
        setShowSale(true);
      } else {
        setShowSale(false);
      }
    } catch (error) {
      console.log("sale data error:", error);
    }
  };

  const getOrderHistory = async () => {
    try {
      const orderHistoryQuery = query(
        ordersCollectionRef,
        where("user", "==", currentUser.uid),
        orderBy("orderDate", "desc"),
        limit(3)
      );

      const snapshot = await getDocs(orderHistoryQuery);

      const orders = snapshot.docs.map(order => {
        const orderData = order.data();
        const orderDate = orderData.orderDate.toDate()

        return {
          publication: orderData.publication,
          dateTime: moment(orderDate).format("DD MMMM, YYYY | HH:mm A")
        }
      });

      setOrders(orders);
    } catch (error) {
      console.log("Order History Error:", error);
    }
  }

  const getSavedCards = async () => {
    try {
      if (!currentUser?.customer_id) {
        console.log("No customer ID found");
        return;
      }

      const response = await fetch(apis.stripe_customer_cards, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          apikey: apiKeys.stripe_subscription,
          action: "get-cards",
          customer_id: currentUser?.customer_id,
        }),
      });

      console.log("Raw response:", response);
      const responseData = await response.json();
      console.log("User cards response", responseData);

      if (responseData.cards) {
        setSavedCards(responseData.cards);
      }
    } catch (err) {
      console.error("Error fetching saved cards :", err);
    }
  };

  const validateBillingDetails = () => {
    const billing = currentUser.billingDetails;
    for (const key in billing) {
      if (key === "companyName" || key === "companyRole" || key === "taxId")
        continue;
      if (billing[key] === "") {
        setBillingDetailsValidation(false);
        return;
      }
    }
    setBillingDetailsValidation(true);
  };

  const handlePublications = () => {
    if (category) {
      setPublication("");
      const filteredPublications = publicationList.filter(
        (pub) => pub.category === category
      );
      setFilteredPublications(filteredPublications);
    } else {
      setFilteredPublications([]);
    }
  };

  const handleAmount = () => {
    if (publication !== "") {
      const selectedPublication = publicationList.find(
        (item) => item.name === publication
      );
      setAmount(selectedPublication.price);
    }
  };

  const onSelectCard = (paymentMethodId) => {
    if (selectedCard === paymentMethodId) {
      setSelectedCard();
    } else {
      setSelectedCard(paymentMethodId);
    }
  };

  const handleOrderPlaced = () => {
    openModal("Order Placed!", "Your order has been successfully placed.", [
      { text: "Close", onClick: closeModal },
    ]);
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `images/${file.name}`);

    try {
      // Upload file
      const snapshot = await uploadBytes(storageRef, file);

      // Get download URL
      const downloadURL = await getDownloadURL(snapshot.ref);

      // Set image URL to state
      setImage(downloadURL);

      // Set selected file name to state
      setSelectedFileName(file.name);
    } catch (error) {
      console.log("Error during the upload", error);
    }
  };

  const updateUserData = async (customerId) => {
    try {
      const userDocRef = doc(usersCollectionRef, currentUser.uid);
      let data = {};
      if (!currentUser?.customer_id) {
        data["customer_id"] = customerId;
        updateCurrentUserData({ customer_id: customerId });
      }
      if (currentUser.balance > 0 && deductFromAccount) {
        data["balance"] = increment(
          currentUser.balance <= amount ? -currentUser.balance : -amount
        );
        updateCurrentUserData({
          balance:currentUser.balance -
          (currentUser.balance <= amount ? currentUser.balance : amount)
        });
      }

      await updateDoc(userDocRef, data);
    } catch (error) {
      console.log("error updating user's customer_id", error);
    }
  };
  async function resendVerificationEmail(email) {
    try {

      const user = auth.currentUser;
      await sendEmailVerification(user);
      // console.log("Resent verification email to:", email);
    } catch (error) {
      console.error("Error resending verification email:", error.message);
    }
  }
  async function manuallySendVerificationEmail(email) {
    try {
      if (email) {
        await resendVerificationEmail(email);
        openModal("Email Sent", "Please check your mail and verify the email", [
          { text: "Close", onClick: closeModal },
        ]);
        //logout();
      }
    } catch (error) {
      console.log("manually send verification email error:", error);
    }
  }
  const handlePay = () => {
    if (!currentUser.emailVerified) {
      openModal(
        "Email Not Verified",
        "Your email is not verified. Please verify your email to continue.",
        [
          // { text: "Close", onClick: closeModal },
          { text: "Cancel", onClick: closeModal },
          {
            text: "Resend Verification Email",
            onClick: () => manuallySendVerificationEmail(currentUser.email),
          },
        ]
      );
    }

  }
  const sendSlackNotification = async (message) => {
    try {
      if (!slackNotification) return;

      const response = await fetch(apis.slack_api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: message }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      console.log("Message sent to Slack successfully");
    } catch (error) {
      setLoading(false);
      console.log("Failed to send message to Slack", error);
    }
  };

  const getTransactionDescription = (userBalance, deductFromAccount, amount) => {
    if (userBalance > 0 && deductFromAccount) {
      if (amount <= userBalance) {
        return `Paid for article with $${amount} account balance`;
      } else {
        const remainingAmount = amount - userBalance;
        return `Paid for article with $${userBalance} account balance and $${remainingAmount} card payment`;
      }
    } else {
      return `Paid for article with $${amount} card payment`;
    }
  }

  const createOrderOnSuccessfulPayment = async (
    amountPaid,
    currency,
    paymentMethodId
  ) => {
    try {
      const orderData = {
        amount: amount,
        article_content: docLink,
        image: image,
        orderCompletedDate: "",
        orderDate: new Date(),
        publication: publication,
        published_url: "",
        status: "Pending",
        userEmail: currentUser.email,
        user: currentUser.uid,
        username: currentUser.username,
        articleWritingRequested,
      };

      const transactionData = {
        amount: amountPaid,
        currency: currency,
        date: new Date(),
        method:
          deductFromAccount && currentUser.balance > amount
            ? "Account Balance"
            : "Stripe",
        paymentID: paymentMethodId,
        status: "Completed",
        userid: currentUser.uid,
        username: currentUser.username,
        description: getTransactionDescription(
          currentUser.balance,
          deductFromAccount,
          amountPaid
        ),
      };

      const orderDocRef = await addDoc(ordersCollectionRef, orderData);
      await addDoc(transactionsCollectionRef, transactionData);

      if (articleWritingRequested) {
        const userResponseData = {
          questions: questions.map((question) => ({
            id: question.id,
            question: question.text,
            answer: answers[question.id],
          })),
        };

        const userResponseCollectionRef = collection(
          orderDocRef,
          "userResponses"
        );
        const userResponseDocRef = doc(userResponseCollectionRef, "data");
        await setDoc(userResponseDocRef, userResponseData);
      }
    } catch (error) {
      setLoading(false);
      console.log("error creating order", error);
    }
  };
  const handleVerifyEmail = () => {
    openModal(
      "Email Not Verified",
      "Your email is not verified. Please verify your email to continue.",
      [
        // { text: "Close", onClick: closeModal },
        { text: "Cancel", onClick: closeModal },
        {
          text: "Resend Verification Email",
          onClick: () => manuallySendVerificationEmail(currentUser.email),
        },
      ]
    );

  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      category &&
      publication &&
      acceptedTerms &&
      (!articleWritingRequested ? docLink : true)
    ) {
      if (!stripe || !elements) {
        console.log("Stripe.js has not yet loaded.");
        return;
      }

      setLoading(true);
      try {
        if (
          currentUser.balance > 0
            ? deductFromAccount
              ? amount > currentUser.balance
                ? amount - currentUser.balance
                : 0
              : amount
            : amount
        ) {
          const orderCost =
            currentUser.balance > 0
              ? deductFromAccount
                ? amount > currentUser.balance
                  ? amount - currentUser.balance
                  : 0
                : amount
              : amount;

          let body = {
            apikey: apiKeys.process_payment,
            amount: orderCost * 100,
            currency: "usd",
            user_id: currentUser.uid,
            name: currentUser.first_name + " " + currentUser.last_name,
            customer_id: currentUser.customer_id,
            email: currentUser.email,
          };
          // Check if a saved card is selected
          if (selectedCard) {
            body = {
              ...body,
              paymentMethodId: selectedCard,
              description: "an order",
            };
          } else {
            // If no saved card is selected, create a new payment method
            const { token, error } = await stripe.createToken(
              elements.getElement(CardElement)
            );

            if (error) {
              console.log("Stripe error:", error.message);
              alert("Stripe Error: " + error.message);
              return;
            }

            body = {
              ...body,
              tokenId: token.id,
              description: "an order",
            };
          }

          // console.log("process payment body:", JSON.stringify(body));

          let response = await fetch(apis.stripe_process_payment, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          });

          response = await response.json();

          // console.log("response", response);
          if (response.error) {
            console.error("Error processing payment:", response);
            alert(response.error.message);
            return;
          } else {
            console.log("Payment successful. Creating order...");

            createOrderOnSuccessfulPayment(
              amount,
              response.data.currency,
              response.data.id
            );

            updateUserData(response.data.customer_id);
          }
        } else {
          createOrderOnSuccessfulPayment(amount, "usd", "");
          updateUserData("");
        }
        // check if slack is on, for now make a local variable
        // title data is not available to send here
        await sendSlackNotification(
          `New order has been placed on Boost PR Panel by ${currentUser.email}. ${JSON.stringify({
            user: currentUser.uid,
            publication: publication,
          })}`
        );

        handleOrderPlaced();
      } catch (e) {
        console.error("An error occurred during payment processing:", e);
        alert("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    } else {
      console.log("Please fill in all required fields and accept the terms.");
    }
  };

  return (
    <>
      <div className="main_p">
        <div className="media_main">
          <img
            src={"/assets/images/main.png"}
            alt="Media"
            className="mediaimg"
          />
          <img
            src={"/assets/images/mback.png"}
            alt="Back"
            className="backimg"
          />
          <div className="media_text">
            <h1 className="media_text1">
              Welcome to <span className="media_span">BOOST MEDIA</span>
            </h1>
            <p className="media_text2">
              The All in One Solution to all your PR Needs
            </p>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {!billingDetailsValidation ? (
            <p className="billing">
              You need to add your billing details on your{" "}
              <Link to={"/user/profile-update"} className="profile_page">
                Profile page
              </Link>{" "}
              before you can place an order.
            </p>
          ) : null}
          {currentUser.emailVerified ? null : (
            <Link onClick={handleVerifyEmail}>
              <p className="billing" style={{ color: "red", marginLeft: 5 }}>
                Verify Email
              </p>
            </Link>
          )}
        </div>
        <div className="forms_p">
          <form className="forms_main" onSubmit={handleSubmit}>
            {showSale ? (
              <div className="forms_off mobile_form_p">
                <div className="sale">{saleData.saleType}</div>
                <div className="off">
                  {saleData.percent} <span className="offspan">OFF</span>
                </div>
                <p className="offpara">
                  {saleData.publication} was{" "}
                  <span className="four">${saleData.price}</span> now only $
                  {saleData.salePrice}
                </p>
              </div>
            ) : null}
            <p className="select_1">Category:</p>
            <div className="select_main">
              <img
                src="https://tuk-cdn.s3.amazonaws.com/can-uploader/arow-%3D%27.%2Cl%3B.png"
                alt="Image"
                className="arrow-r"
              />
              <select
                className="select_field"
                id="category"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
                required>
                <option value="" selected disabled>
                  Please select a category
                </option>
                {categoryList.map((cat) => {
                  if (!cat.displayStatus) return;
                  return (
                    <option key={cat.name} value={cat.name}>
                      {cat.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <br />
            <p className="select_1">Publication:</p>
            <div className="select_main">
              <img
                src="https://tuk-cdn.s3.amazonaws.com/can-uploader/arow-%3D%27.%2Cl%3B.png"
                alt="arrow down icon"
                className="arrow-r"
              />
              <select
                className="select_field"
                id="publication"
                value={publication}
                onChange={(e) => setPublication(e.target.value)}
                required>
                <option value="" selected disabled>
                  Please Select a Publication
                </option>
                {filteredPublications.map((pub) => (
                  <option key={pub.name} value={pub.name}>
                    {`${pub.name} - $${pub.price}`}
                  </option>
                ))}
              </select>
            </div>
            <br />
            <p className="select_1">Article Image</p>
            <div class="custom-file">
              <input
                type="file"
                className="custom-file__input"
                id="field-upload"
                name="upload"
                onChange={handleUpload}
                required
              />
              <label class="custom-file__label" for="field-upload">
                <svg
                  className="upload_svg"
                  width={30}
                  height={28}
                  viewBox="0 0 30 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <ellipse
                    cx="15.131"
                    cy={14}
                    rx="14.2692"
                    ry={14}
                    fill="#EAFFF0"
                  />
                  <path
                    d="M17.9171 16.9918L15.2911 14.4677L12.6651 16.9918"
                    fill="#EAFFF0"
                  />
                  <path
                    d="M17.9171 16.9918L15.2911 14.4677L12.6651 16.9918"
                    stroke="#62C57E"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.2928 14.4677V20.1469"
                    stroke="#62C57E"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.8012 18.5C21.4415 18.1645 21.9473 17.6335 22.2389 16.991C22.5304 16.3484 22.591 15.6309 22.4111 14.9516C22.2312 14.2722 21.8211 13.6698 21.2454 13.2394C20.6698 12.809 19.9614 12.5751 19.2322 12.5747H18.405C18.2063 11.8359 17.8359 11.15 17.3217 10.5686C16.8075 9.98722 16.1629 9.52544 15.4363 9.21797C14.7097 8.91051 13.9201 8.76537 13.1268 8.79346C12.3334 8.82156 11.557 9.02216 10.856 9.38018C10.1549 9.73821 9.54737 10.2443 9.07909 10.8605C8.61081 11.4767 8.29396 12.1869 8.15234 12.9378C8.01073 13.6886 8.04804 14.4605 8.26148 15.1955C8.47491 15.9305 8.85891 16.6094 9.38461 17.1812"
                    fill="#EAFFF0"
                  />
                  <path
                    d="M20.8012 18.5C21.4415 18.1645 21.9473 17.6335 22.2389 16.991C22.5304 16.3484 22.591 15.6309 22.4111 14.9516C22.2312 14.2722 21.8211 13.6698 21.2454 13.2394C20.6698 12.809 19.9614 12.5751 19.2322 12.5747H18.405C18.2063 11.8359 17.8359 11.15 17.3217 10.5686C16.8075 9.98722 16.1629 9.52544 15.4363 9.21797C14.7097 8.91051 13.9201 8.76537 13.1268 8.79346C12.3334 8.82156 11.557 9.02216 10.856 9.38018C10.1549 9.73821 9.54737 10.2443 9.07909 10.8605C8.61081 11.4767 8.29396 12.1869 8.15234 12.9378C8.01073 13.6886 8.04804 14.4605 8.26148 15.1955C8.47491 15.9305 8.85891 16.6094 9.38461 17.1812"
                    stroke="#62C57E"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.9171 16.9918L15.2911 14.4677L12.6651 16.9918"
                    fill="#EAFFF0"
                  />
                  <path
                    d="M17.9171 16.9918L15.2911 14.4677L12.6651 16.9918"
                    stroke="#62C57E"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {selectedFileName ? selectedFileName : "Attach an image"}
              </label>
            </div>
            {professionalWriterStatus ? (
              <div className="check_main">
                <input
                  type="checkbox"
                  className="check_1"
                  id="article-request"
                  checked={articleWritingRequested}
                  onChange={() => setArticleWritingRequested((prev) => !prev)}
                />
                <label htmlFor="article-request" className="check_2">
                  Get a professional Article Written
                </label>
              </div>
            ) : null}
            {articleWritingRequested ? (
              <>
                <div className="AI-Form">
                  <div className="format-explain">
                    <p className="select_1">
                      Answer all the questions in as much detail as possible and
                      we will write a professional news article for you with the
                      provided information.
                    </p>
                    <br />
                  </div>
                  <Questionnaire
                    questions={questions}
                    answers={answers}
                    handleAnswerChange={handleAnswerChange}
                  />
                </div>
                <br />
              </>
            ) : (
              <>
                <p className="select_1">Google Doc Content Link:</p>
                <div className="select_main">
                  <input
                    type="url"
                    id="docLink"
                    placeholder="Paste your article content link here"
                    className="select_field"
                    value={docLink}
                    onChange={(e) => setDocLink(e.target.value)}
                    required
                  />
                </div>
                <br />
              </>
            )}
            <p className="select_1">Add Card Information</p>
            <div className="form-group">
              <CardElement
                className="CardElement"
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      width: "100%",
                      padding: "10px",
                      color: "#32325d",
                      fontFamily: "Arial, sans-serif",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "#fa755a",
                    },
                  },
                }}
              />
            </div>
            {savedCards.length > 0 ? (
              <>
                <p className="input-label">or select a saved card</p>
                <SavedCardsTable
                  savedCards={savedCards}
                  selectedCard={selectedCard}
                  onSelectCard={onSelectCard}
                />
              </>
            ) : null}
            <label className="payment-method">
              <br />
              <br />
              <img src="./../cards-img.png" alt="cards" />
            </label>
            {currentUser?.balance > 0 ? (
              <div className="check_main">
                <input
                  type="checkbox"
                  id="account-balance"
                  className="check_1"
                  checked={deductFromAccount}
                  onChange={() => setDeductFromAccount((prev) => !prev)}
                />
                <label htmlFor="account-balance" className="check_2">
                  Deduct $
                  {currentUser.balance <= amount
                    ? parseFloat(currentUser.balance)
                    : amount}
                  {publication.trim() === "" && !amount
                    ? currentUser.balance
                    : ""}{" "}
                  from my balance
                </label>
              </div>
            ) : null}
            <div className="check_main">
              <input
                type="checkbox"
                id="terms"
                className="check_1"
                checked={acceptedTerms}
                onChange={() => setAcceptedTerms(!acceptedTerms)}
                required
              />
              <label htmlFor="terms" className="check_2">
                I agree to the{" "}
                <Link to="../refundpolicy">Client Refund Policy Agreement</Link>
              </label>
            </div>
            <button
              type="submit"
              onClick={handlePay}
              className="paybtn"
              disabled={loading || !billingDetailsValidation}>
              {loading ? (
                <ClipLoader
                  color="#f5f5f5"
                  loading={loading}
                  size={15}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                <>
                  Pay
                  <span className="payspan">{`${
                    currentUser.balance > 0
                      ? deductFromAccount
                        ? amount > currentUser.balance
                          ? `$${amount - currentUser.balance}`
                          : "$0"
                        : amount
                        ? `$${amount}`
                        : ""
                      : amount
                      ? `$${amount}`
                      : ""
                  }`}</span>
                </>
              )}
            </button>
          </form>
          <div className="forma_off_main">
            {showSale ? (
              <div className="forms_off">
                <div className="sale">{saleData.saleType}</div>
                <div className="off">
                  {saleData.percent} <span className="offspan">OFF</span>
                </div>
                <p className="offpara">
                  {saleData.publication} was{" "}
                  <span className="four">${saleData.price}</span> now only $
                  {saleData.salePrice}
                </p>
              </div>
            ) : null}
            <br />
            {orders.length > 0 ? (
              <>
                <div className="forms_off">
                  <div className="record">
                    <p className="order_record">Orders Record</p>
                    <Link to={"/user/order-history"}>
                      <p className="viewall">View All</p>
                    </Link>
                  </div>
                  <br />
                  {orders.map((order, index) => (
                    <>
                      <div className="placed" key={index}>
                        <div className="one">0{index + 1}</div>
                        <div>
                          <p className="new">{order.publication}</p>
                          <p className="date">{order.dateTime}</p>
                        </div>
                      </div>
                      {index !== orders.length - 1 ? <br /> : null}
                    </>
                  ))}
                </div>
                <br />
              </>
            ) : null}
            <div className="forms_off">
              <img
                src={"/assets/images/contact.png"}
                alt="Contact"
                className="contactimg"
              />
              <button className="paybtn" onClick={navigateToSupportPage}>
                CONTACT SUPPORT
              </button>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        isOpen={isModalOpen}
        title={modalContent.title}
        description={modalContent.description}
        buttons={modalContent.buttons}
        closeModal={closeModal}
      />
    </>
  );
}

const OrderPage = () => {
  const [apiKeys, setApiKeys] = useState({
    stripe_publishable_key: "",
  });

  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        const apiKeys2 = await getLocalApiKeys();
        setApiKeys(apiKeys2);
        return apiKeys2;
      } catch (error) {
        console.error("Error fetching api keys:", error);
      }
    };

    fetchApiKeys().then((apiKeys) => {
      const stripePromise = loadStripe(apiKeys.stripe_publishable_key);
      setStripePromise(stripePromise);
    });
  }, []);

  const [stripePromise, setStripePromise] = useState(null);

  return (
    apiKeys.stripe_publishable_key !== "" &&
    stripePromise && (
      <Elements stripe={stripePromise}>
        <OrderForm />
      </Elements>
    )
  );
};



export default OrderPage
