import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Notification from '../../../components/Notification';
import CustomModal from '../../../components/modals/CustomModal';

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../../../firebase/firebase';

import { doc, getDoc, updateDoc } from "firebase/firestore";
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  verifyBeforeUpdateEmail,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import { auth } from '../../../firebase/firebase';
import { usersCollectionRef } from "../../../firebase/firestore/collections";
import { getLocalApiLinks } from '../../../utils/localStorage/apiLinks';

import { useAuth } from '../../../contexts/AuthContext';

function ProfileUpdatePage() {
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();

  const [selectedImage, setSelectedImage] = useState(null);

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor (Timor-Leste)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  // State variables for user information
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [username, setUsername] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [profileUpdateMessage, setProfileUpdateMessage] = useState("");
  // company
  const [isCompany, setIsCompany] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [taxId, setTaxId] = useState("");
  const [companyRole, setCompanyRole] = useState("");

  const [profilePic, setProfilePic] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  // change email/password
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordUpdatedSuccessfully, setPasswordUpdated] = useState(false);
  const [emailUpdatedSuccessfully, setEmailUpdated] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    description: "",
    buttons: [],
  });
  const [apis, setApis] = useState({});

  useEffect(() => {
    const fetchApis = async () => {
      try {
        const apiLinks = await getLocalApiLinks();

        setApis(apiLinks);
      } catch (error) {
        console.error("Error fetching api links:", error);
      }
    };

    fetchApis();
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [currentUser]);

  const openModal = (title, description, buttons = []) => {
    setModalContent({
      title,
      description,
      buttons,
    });
    setModalIsOpen(true);
  };

  const closeModal = async () => {
    setModalIsOpen(false);
    if (emailUpdatedSuccessfully || passwordUpdatedSuccessfully) {
      await logout();
      navigate("/");
    }
  };

  const fetchUserData = async () => {
    if (currentUser?.uid) {
      const userDocRef = doc(usersCollectionRef, currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setEmail(userData.email || "");
        setFirstName(userData.first_name || "");
        setLastName(userData.last_name || "");
        setPhoneNumber(userData.phone_number || "");
        setUsername(userData.username || "");
        setProfilePic(userData.pic || "");
        setIsCompany(userData.isCompany || false);

        // Now fetch the nested BillingDetails
        const billingDetailsDocRef = doc(userDocRef, "BillingDetails", "data");
        const billingDetailsSnap = await getDoc(billingDetailsDocRef);

        if (billingDetailsSnap.exists()) {
          const billingDetailsData = billingDetailsSnap.data();
          setAddressLine1(billingDetailsData.addressLine1 || "");
          setAddressLine2(billingDetailsData.addressLine2 || "");
          setCity(billingDetailsData.city || "");
          setCountry(billingDetailsData.country || "");
          setRegion(billingDetailsData.region || "");
          setZipCode(billingDetailsData.zipCode || "");
          setCompanyName(billingDetailsData.companyName || "");
          setCompanyRole(billingDetailsData.companyRole || "");
          setTaxId(billingDetailsData.taxId || "");
        } else {
          console.log("No billing details found!");
        }
      } else {
        console.log("No user document found!");
      }
    }
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Display the selected image as a preview
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(file);

    const storageRef = ref(storage, `profile-pics/${file.name}`);

    try {
      // Upload file
      const snapshot = await uploadBytes(storageRef, file);

      // Get download URL
      const downloadURL = await getDownloadURL(snapshot.ref);

      // Set profile picture URL to state
      setProfilePic(downloadURL);

      // Set upload progress back to 0
      setUploadProgress(0);
    } catch (error) {
      console.log("Error during the upload", error);
    }
  };

  const updateBillingDetails = async (customerData) => {
    try {
      const response = await fetch(apis.stripe_update_billing_details, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(customerData),
      });

      if (!response.ok) {
        console.error(`Failed with status: ${response.status}`);
        const errorText = await response.text();
        console.error(`Error response: ${errorText}`);
        throw new Error(`Failed with status: ${response.status}`);
      }

      const data = await response.json();

      // Assuming your server sends a response like { success: true } on successful update
      if (!data.error) {
        console.log("Billing details updated successfully.");
        return true;
      } else {
        console.log("Failed to update billing details.");
        // Handle the failure case, show a message, etc.
        return false;
      }
    } catch (error) {
      console.error("Error updating billing details:", error.message);
      // Handle the error, show a message, etc.
      return false;
    }
  };

  const changeEmail = async () => {
    if (
      newEmail.trim() &&
      currentUser.email.trim() &&
      newEmail !== currentUser.email
    ) {
      try {
        setEmailUpdated(false);
        let user = auth.currentUser;

        const credentials = EmailAuthProvider.credential(user.email, password);

        await reauthenticateWithCredential(user, credentials);
        await verifyBeforeUpdateEmail(user, newEmail);

        setEmail("");
        setPassword("");
        setEmailUpdated(true);

        openModal(
          "Email Updated Successfully",
          "Please verify your new email via the mail you have recieved on your new email.\n After verfication of the new email, you will need to login to your account again",
          [{ text: "Close", onClick: closeModal }]
        );
      } catch (error) {
        console.log("Error updating email: ", error.message);
        setEmailUpdated(false);
        openModal("Email Update Failed", "Please try again later", [
          { text: "Close", onClick: closeModal },
        ]);
        return;
      }
    }
  };

  const changePassword = async () => {
    if (
      newPassword.trim() &&
      currentPassword.trim() &&
      newPassword !== currentPassword
    ) {
      if (newPassword.length < 6) {
        console.log("Password should be at least 6 characters.");
        return;
      }

      try {
        setPasswordUpdated(false);
        let user = auth.currentUser;

        const credentials = EmailAuthProvider.credential(
          user.email,
          currentPassword
        );
        await reauthenticateWithCredential(user, credentials);
        await updatePassword(user, newPassword);

        setNewPassword("");
        setCurrentPassword("");
        setPasswordUpdated(true);
        openModal(
          "Password Updated Successfully",
          "You will need to login to your account again",
          [{ text: "Close", onClick: closeModal }]
        );
      } catch (error) {
        //  console.log("Error updating password: ", error.message);
        setPasswordUpdated(false);
        openModal("Password Update Failed", "Please try again later", [
          { text: "Close", onClick: closeModal },
        ]);
        return;
      }
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();

    if (!currentUser?.uid) {
      console.log("No user ID found");
      return;
    }

    try {
      setProfileUpdateMessage("");

      const userDocRef = doc(usersCollectionRef, currentUser.uid);
      const billingDetailsRef = doc(userDocRef, "BillingDetails", "data");

      let billingDetailsData = {
        addressLine1,
        addressLine2,
        city,
        country,
        region,
        zipCode,
        companyName: "",
        taxId: "",
        companyRole: "",
      };

      if (isCompany) {
        billingDetailsData = {
          ...billingDetailsData,
          companyName,
          taxId,
          companyRole,
        };
      }

      await updateDoc(userDocRef, {
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        username,
        pic: profilePic,
        isCompany,
      });
      await updateDoc(billingDetailsRef, billingDetailsData);

      console.log("Profile updated successfully.");
      setProfileUpdateMessage("Profile updated successfully");
    } catch (error) {
      console.log("Error updating profile: ", error);
      // Handle profile update errors here
    }

    const customerData = {
      customerId: currentUser.customer_id || "",
      email: currentUser.email,
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      city,
      country,
      region,
      zipCode,
    };
    // console.log(customerData);

    // Call the function to update billing details via POST request
    const billingDetailsUpdated = await updateBillingDetails(customerData);

    if (billingDetailsUpdated) {
      console.log("Billing details updated successfully.");
      // You can add additional logic or UI updates here if needed
    } else {
      console.log("Failed to update billing details.");
      // Handle the failure case, show a message, etc.
    }

    console.log("The Response of the Billing Details Request");
  };

  return (
    <>
      <div className="card_container">
        <div
          className="profile-container"
          // style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="profile-form">
            <form className="profile-details" onSubmit={handleUpdateProfile}>
              <h1 className="title">Manage Profile</h1>

              <div className="name-inputs">
                <input
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>

              <div className="name-inputs">
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>
              <p class="input-label">Profile Picture</p>
              <input type="file" onChange={handleUpload} />

              {/* Preview of the selected image */}
              {selectedImage || profilePic ? (
                <div>
                  <p>Selected Image Preview:</p>
                  <img
                    src={selectedImage || profilePic}
                    alt="Selected Preview"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              ) : null}

              <p class="input-label">Billing Details</p>
              <input
                type="text"
                placeholder="Address Line 1"
                value={addressLine1}
                onChange={(e) => setAddressLine1(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Address Line 2"
                value={addressLine2}
                onChange={(e) => setAddressLine2(e.target.value)}
                required
              />

              <div className="name-inputs">
                <input
                  type="text"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Region/State"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  required
                />

                <input
                  type="text"
                  placeholder="Zip/Postal Code"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  required
                />
              </div>

              <select
                value={country}
                className="form-select"
                onChange={(e) => setCountry(e.target.value)}
                required
              >
                <option value="" disabled>
                  Select Country
                </option>
                {countries.map((countryOption) => (
                  <option key={countryOption} value={countryOption}>
                    {countryOption}
                  </option>
                ))}
              </select>

              <div>
                <div className="form-group">
                  <label htmlFor="company" className="input-checkbox">
                    <input
                      type="checkbox"
                      id="company"
                      value="company"
                      checked={isCompany}
                      onChange={(e) => {
                        const { checked } = e.target;
                        setIsCompany(checked);
                      }}
                    />
                    Register as Company
                  </label>
                </div>
                {isCompany && (
                  <div>
                    <input
                      type={"text"}
                      placeholder="Company Name"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      required={isCompany}
                    />

                    <input
                      type={"text"}
                      placeholder="Your role in the Company"
                      value={companyRole}
                      onChange={(e) => setCompanyRole(e.target.value)}
                      required={isCompany}
                    />

                    <input
                      type={"text"}
                      placeholder="Tax ID"
                      value={taxId}
                      onChange={(e) => setTaxId(e.target.value)}
                    />
                  </div>
                )}
              </div>
              <button className="paybtn " type="submit">
                Update Profile
              </button>
            </form>
          </div>
          <br />
          <hr />
          <br />
          {/* change email */}
          <div>
            <p class="input-label">New Email</p>
            <input
              type="text"
              className="form-input"
              placeholder="New Email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
            <p class="input-label">Password</p>
            <input
              type="text"
              name="password"
              id="password"
              className="form-input"
              placeholder="Current Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className="paybtn contain-width" onClick={changeEmail}>
              Change Email
            </button>
          </div>

          <br />
          <hr />
          <br />

          {/* change password */}
          <div className="password-section">
            <div className="change-password">
              {/* <h2>Change Password</h2> */}
              <p class="input-label">Current Password</p>
              <input
                type="password"
                placeholder="Current Password"
                className="form-input"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <p class="input-label">New Password</p>
              <input
                type="password"
                placeholder="Enter New Password"
                className="form-input"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <button className="paybtn contain-width" onClick={changePassword}>
                Change Password
              </button>
            </div>
          </div>
        </div>
      </div>

      {profileUpdateMessage && profileUpdateMessage.trim() !== "" ? (
        <Notification message={profileUpdateMessage} />
      ) : null}
      <CustomModal
        isOpen={modalIsOpen}
        title={modalContent.title}
        description={modalContent.description}
        buttons={modalContent.buttons}
        closeModal={closeModal}
      />
    </>
  );
}

export default ProfileUpdatePage;
