import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { getDocs } from "@firebase/firestore";
import { prServicesDataCollectionRef } from "../../firebase/firestore/collections";

function ServicesPage() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [siteData, setSiteData] = useState([]);
  const [filters, setFilters] = useState({
    price: "",
    DA: "",
    PA: "",
    traffic: "",
    TAT: ""
  });
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    getSites();
  }, []);

  useEffect(() => {
    setFilteredData(applyFilters(siteData, filters));
  }, [filters, siteData]);

  const getSites = async () => {
    try {
      const sitesDocSnap = await getDocs(prServicesDataCollectionRef);
      const sites = sitesDocSnap.docs.map((doc) => doc.data());
      setSiteData(sites);
      setLoading(false); // Set loading to false after fetching data
    } catch (error) {
      console.log("get services error", error);
      setLoading(false); // Handle loading state in case of an error
    }
  };

  function applyFilters(data, filters) {
    return data.filter((site) => {
      const sitePrice = typeof site.price === 'string' ? parseFloat(site.price.replace("$", "")) : 0;
      // console.log("Site Price:", sitePrice);

      // Use lowercase property names for filtering
      const siteDA = parseInt(site.da || 0, 10);
      // console.log("Site DA:", siteDA);
      const sitePA = parseInt(site.pa || 0, 10);
      // console.log("Site PA:", sitePA);

      let siteTraffic = 0;
      let siteTAT = 0;

      // Check if site.traffic and site.TAT are defined and typeof string
      if (typeof site.traffic === 'string') {
        const trafficMatch = site.traffic.match(/\d+/); // Extract numbers from string
        if (trafficMatch) {
          siteTraffic = parseInt(trafficMatch[0] + "000", 10);
        }
      }

      // console.log("Site Traffic:", siteTraffic);

      if (typeof site.tat === 'string') { // Use lowercase property name for TAT
        const tatMatch = site.tat.match(/\d+/); // Extract numbers from string
        if (tatMatch) {
          siteTAT = parseInt(tatMatch[0], 10);
        }
      }
      // console.log("Site TAT:", siteTAT);

      const filterConditions = (
        (!filters.price || sitePrice <= parseFloat(filters.price)) &&
        (!filters.DA || siteDA >= parseInt(filters.DA, 10)) &&
        (!filters.PA || sitePA >= parseInt(filters.PA, 10)) &&
        (!filters.traffic || siteTraffic >= parseInt(filters.traffic, 10)) &&
        (!filters.TAT || siteTAT <= parseInt(filters.TAT, 10))
      );


      return filterConditions;
    });
  }

  function handleFilterChange(e) {
    setError("");

    const { name, value } = e.target;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));

    if (name === "DA" && value && value < 34) setError("Minimum DA value: 34");
  }

  return (
    <div className="content">
      <div className="card_container">
        <div className="filters-container">
          <div className="filter">
            <input
              className="form-input"
              id="price-filter"
              type="number"
              name="price"
              value={filters.price}
              onChange={handleFilterChange}
              placeholder="Max Price"
            />
          </div>
          <div className="filter">
            <input
              className="form-input"
              id="da-filter"
              type="number"
              name="DA"
              value={filters.DA}
              onChange={handleFilterChange}
              placeholder="Min DA"
            />
          </div>
          <div className="filter">
            <input
              id="pa-filter"
              className="form-input"
              type="number"
              name="PA"
              value={filters.PA}
              onChange={handleFilterChange}
              placeholder="Min PA"
            />
          </div>
          {/* <div className="filter">
            <label htmlFor="traffic-filter">Min Traffic:</label>
            <input
              id="traffic-filter"
              className="form-input"
              type="text"
              name="traffic"
              value={filters.traffic}
              onChange={handleFilterChange}
              placeholder="e.g., 1000"
            />
          </div> */}
          <div className="filter">
            <input
              id="tat-filter"
              className="form-input"
              type="text"
              name="TAT"
              value={filters.TAT}
              onChange={handleFilterChange}
              placeholder="Max TAT (days)"
            />
          </div>
        </div>
        {error && (
          <p className="select_1" style={{ color: "#d31414" }}>
            {error}
          </p>
        )}
      </div>
      {loading ? (
        <div className="loader-container">
          <ClipLoader color="#000" loading={loading} size={150} />
        </div>
      ) : (
        <SitesTable data={filteredData} />
      )}
    </div>
  );
}

function SitesTable({ data }) {
  return (
    <div className="t_main">
      <div className="co_main">
        <table className="table_main">
          <thead className="onetr">
            <tr>
              <th className="od">Site</th>
              <th className="od">Price</th>
              <th className="od">DA</th>
              <th className="od">PA</th>
              <th className="od">Traffic</th>
              <th className="od">TAT</th>
              <th className="od">Region</th>
            </tr>
          </thead>
          <tbody>
            {!data.length ? (
              <tr className="s_tdp">
                <td className="tdp" colSpan={"7"}>
                  No sites to display
                </td>
              </tr>
            ) : null}
            {data.map((site, index) => (
              <tr key={index} className={index % 2 === 0 ? "s_tdp" : ""}>
                <td className="tdp">{site.name}</td>
                <td className="tdp">{site.price}</td>
                <td className="tdp">{site.da}</td>
                <td className="tdp">{site.pa}</td>
                <td className="tdp">{site.traffic}</td>
                <td className="tdp">{site.tat}</td>
                <td className="tdp">{site.region}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ServicesPage;
