// Import necessary libraries and components
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { ordersCollectionRef } from '../../../firebase/firestore/collections';
import { query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';

// OrderHistoryTable component
function OrderHistoryTable({ data }) {
  const navigate = useNavigate();

  return (
    <div className="t_main">
      <div className="co_main">
        <table className="table_main">
          <thead>
            <tr className="onetr">
              <th className="od">Order Date</th>
              <th className="od">Publication</th>
              <th className="od">Published URL</th>
              <th className="od">Status</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr className="s_tdp">
                <td className="tdp" colSpan={4}>
                  No order history available
                </td>
              </tr>
            ) : (
              data.map((order, index) => (
                <tr
                  key={order.orderID}
                  className={[index % 2 === 0 ? 's_tdp' : '', 'table-row'].join(' ')}
                  onClick={() => navigate(`/order/${order.orderID}`)}
                >
                  <td className="tdp" data-label="Order Date">
                    {order.orderDate}
                  </td>
                  <td className="tdp" data-label="Publication">
                    {order.publication}
                  </td>
                  <td className="tdp" data-label="Published URL">
                    {order.status !== 'Cancelled' ? (
                      order.publishedURL !== 'Yet to be published' ? (
                        <a
                          href={order.publishedURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Click to open
                        </a>
                      ) : (
                        order.publishedURL
                      )
                    ) : (
                      'Order was cancelled'
                    )}
                  </td>
                  <td className="tdp">
                    <p
                      className={
                        order.status === 'In-Progress'
                          ? 'tdp_stat'
                          : order.status === 'Pending'
                          ? 'pending'
                          : order.status === 'Cancelled'
                          ? 'cancelled'
                          : 'tdp_stat'
                      }
                      data-label="Status"
                    >
                      {order.status}
                    </p>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

// OrderHistoryPage component
function OrderHistoryPage() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchOrders = async () => {
      if (currentUser) {
        try {
          setLoading(true);
          // Construct a query against the collection.
          const ordersQuery = query(
            ordersCollectionRef,
            where('user', '==', currentUser.uid),
            orderBy('orderDate', 'desc'),
            limit(20)
          );
          const querySnapshot = await getDocs(ordersQuery);

          if (querySnapshot.empty) {
            setOrders([]);
          } else {
            const fetchedOrders = querySnapshot.docs.map((doc) => {
              // Convert the Timestamp to a Date object
              const date = doc.data().orderDate.toDate();

              // Format the date as a string (e.g., 'YYYY-MM-DD')
              const formattedDate = date.toISOString().split('T')[0];

              return {
                orderID: doc.id,
                orderDate: formattedDate,
                publication: doc.data().publication,
                publishedURL: doc.data().published_url || 'Yet to be published',
                status: doc.data().status,
              };
            });

            setOrders(fetchedOrders);
          }
        } catch (error) {
          console.log('Error fetching orders: ', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchOrders();
  }, [currentUser]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-container">
      <h1 className="currentorder">Order History</h1>
      <OrderHistoryTable data={orders} />
    </div>
  );
}

export default OrderHistoryPage;
