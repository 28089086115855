import React, { useEffect, useState  } from "react";
import { Link } from "react-router-dom";
import { getDocs, deleteDoc, doc } from "firebase/firestore";
import { usersCollectionRef } from "../../../firebase/firestore/collections";
import { MdModeEdit, MdDelete } from "react-icons/md";
import './UserManagement.css';
import { deleteUser } from "firebase/auth";
import {CSVLink} from "react-csv";
const columns = [
  {
    accessorKey: "id",
    header: "UID",
    cell: (props) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "first_name",
    header: "First Name",
    cell: (props) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "last_name",
    header: "Last Name",
    cell: (props) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "username",
    header: "Username",
    cell: (props) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "email",
    header: "Email",
    cell: (props) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "phone_number",
    header: "Phone Number",
    cell: (props) => <p>{props.getValue()}</p>,
  },
  {
    accessorKey: "date_created",
    header: "Date Created", // New column for date_created
    cell: (props) => <p>{new Date(props.getValue()).toLocaleDateString()}</p>,
  },
  {
    accessorKey: "isAdmin",
    header: "Admin",
    cell: (props) => <p>{JSON.stringify(props.getValue())}</p>,
  },
  {
    accessorKey: "id",
    header: "Edit",
    cell: (props) => (
      <Link to={`/admin/edit-user/${props.getValue()}`}>
        <MdModeEdit />
      </Link>
    ),
  },
  {
    accessorKey: "delete",
    header: "Delete",
    cell: (props) => (
      <button onClick={() => props.handleDelete(props.rowId)}>
        <MdDelete />
      </button>
    ),
  },
];

function UserManagementPage() {
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      setLoading(true);
      const docsSnap = await getDocs(usersCollectionRef);
      let users = [];
      docsSnap.docs.map((doc) => users.push({ ...doc.data(), id: doc.id }));
      setUsersList(users);
    } catch (error) {
      console.log("Error getting all users", error);
    } finally {
      setLoading(false);
    }
  };
  const deleteAuthAdmin = async (userId) => {
    try {
      const response = await fetch(
        `https://us-central1-pr-system-37dbc.cloudfunctions.net/deleteUserHttp?userId=${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Error deleting user");
      }

      const data = await response.json();
      console.log(data); // Log the response from the server
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  const exportUserDetails = () => {
    const dataToExport = usersList.map((user) => ({
      Name: user.first_name,
      Email: user.email,
      MobileNumber: user.phone_number,
    }));
    return dataToExport;
  };

  const handleConfirmDelete = async (confirmation) => {
    if (confirmation) {
      try {
        setLoading(true);
        const docRef = doc(usersCollectionRef, userToDelete);
        await deleteDoc(docRef);
        await deleteAuthAdmin(userToDelete);
        setUsersList(usersList.filter((user) => user.id !== userToDelete));
        console.log("Document successfully deleted!");
        alert("User deleted successfully!");
      } catch (error) {
        console.error("Error deleting user:", error);
      } finally {
        setShowDeleteDialog(false);
        setUserToDelete(null);
        setLoading(false);
      }
    } else {
      setShowDeleteDialog(false);
      setUserToDelete(null);
    }
  };

  const handleDeleteUser = (userId) => {
    setUserToDelete(userId);
    setShowDeleteDialog(true);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="page-container">
      <CSVLink
        data={exportUserDetails()}
        filename={"user-details.csv"}
        className="export-button">
        <p style={{fontSize:18}}>Export User Details</p>
      </CSVLink>
      <div className="table-container">
        <table className="custom-table">
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.accessorKey}>{column.header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {usersList.map((user) => (
              <tr key={user.id}>
                {columns.map((column) => (
                  <td key={`${user.id}-${column.accessorKey}`}>
                    {column.cell({
                      getValue: () => user[column.accessorKey],
                      handleDelete: handleDeleteUser,
                      rowId: user.id,
                    })}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showDeleteDialog && (
        <div className="delete-dialog">
          <div className="delete-dialog-content">
            <p>Do you want to delete this user?</p>
            <div className="delete-dialog-buttons">
              <button onClick={() => handleConfirmDelete(true)}>Yes</button>
              <button onClick={() => handleConfirmDelete(false)}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}


export default UserManagementPage;
