import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";

function ForgotPasswordPage() {
  const emailRef = useRef();

  const { resetPassword } = useAuth();

  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage("Check you inbox for further instructions");
    } catch (error) {
      // console.log("login error", error.message);
      setError("Failed to reset password");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <h1 className="boost_8">Reset Password</h1>
      {/* Add some modal type of component here to show the error message */}
      {error}
      {message}
      <form className="boost_11" onSubmit={handleSubmit}>
        <div>
          <p className="boost_12">
            Enter your Email Address to reset your password
          </p>
          <div className="boost_13">
            <svg
              className="message"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.5 5.25L2.25 4.5H21.75L22.5 5.25V18.75L21.75 19.5H2.25L1.5 18.75V5.25ZM3 6.8025V18H21V6.804L12.465 13.35H11.55L3 6.8025ZM19.545 6H4.455L12 11.8035L19.545 6Z"
                fill="#62C57E"
              />
            </svg>
            <input
              type="email"
              ref={emailRef}
              placeholder="Email Address"
              className="boost_14"
              required
            />
          </div>
        </div>

        <button type="submit" disabled={loading} className="loginbtn">
          Send Email
        </button>
      </form>

      <hr className="hr" />
      <div>
        <p className="register">
          <Link to="/" className="now">Login</Link>
        </p>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
