import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { query, orderBy, getDocs, doc, deleteDoc  } from 'firebase/firestore';
import { prServicesDataCollectionRef } from '../../../firebase/firestore/collections';


function PRServicesTable({ prServices, onEdit, onDelete }) {
  if (!prServices.length > 0) {
    return <p>No Data Available</p>;
  }

  return (

    <div className='table-container'>
      <table className='custom-table'>
      <thead>
        <tr>
          <th>ID</th>
          <th>Publication Name</th>
          <th>Category</th>
          <th>Selling Price</th>
          <th>Status</th>
          <th>Edit</th>
        </tr>
      </thead>
      <tbody>
        {prServices.map((service, index) => (
          <tr key={service.id}>
            <td>{service.id}</td>
            <td>{service.name}</td>
            <td>{service.category}</td>
            <td>{service.sellingPrice}</td>
            <td>{service.status ? "Active" : "Inactive"}</td>
            <td>
              <button onClick={() => onEdit(service.id)}>Edit</button>{" "}
              <button onClick={() => onDelete(service.id)}>Delete</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  );
}



function PRServicesPage() {
  const [prServices, setPrServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    
    const fetchPrServices = async () => {
      try {
        setLoading(true);
        const q = query(prServicesDataCollectionRef, orderBy('name', 'asc'));
        const querySnapshot = await getDocs(q);

        const services = querySnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name,
          category: doc.data().site_cat,
          sellingPrice: doc.data().price,
          status: doc.data().status,
        }));

        setPrServices(services);
      } catch (error) {
        console.log("Error fetching PR services:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPrServices();
  }, []);

  const handleDelete = async (serviceId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this service?");
    if (confirmDelete) {
      try {
        await deleteDoc(doc(prServicesDataCollectionRef, serviceId));
        // Update the prServices state to reflect the deletion
        setPrServices(prServices.filter(service => service.id !== serviceId));
      } catch (error) {
        console.log("Error deleting PR service:", error);
      }
    }
  };

  function handleEdit(id) {
    navigate(`./edit/${id}`);
  }

  function navigateToAddPrServices() {
    navigate('./add');
  }

  function navigateToPrCategories() {
    navigate("./categories");
  }

  if (loading) {
    return <div>Loading PR services...</div>;
  }

  return (
    <div className="page-container">
      <h2>PR Services</h2>
      <div>
        <button
          onClick={navigateToAddPrServices}
          style={{ marginRight: "10px" }}
        >
          Add PR Service
        </button>
        <button onClick={navigateToPrCategories}>Manage PR Categories</button>
      </div>
      <br />

      <PRServicesTable
        prServices={prServices}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </div>
  );
}

export default PRServicesPage;
