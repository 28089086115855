import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { addDoc, getDocs } from 'firebase/firestore';
import { prServicesDataCollectionRef, prServicesCatsDataCollectionRef } from '../../../firebase/firestore/collections';

function AddPrServicePage() {
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [price, setPrice] = useState('');
  const [status, setStatus] = useState(false);
  const [da, setDa] = useState('');
  const [pa, setPa] = useState('');
  const [tat, setTat] = useState('');
  const [url, setUrl] = useState('');
  const [traffic, setTraffic] = useState('');
  const [region, setRegion] = useState('');
  const [dofollow, setDofollow] = useState(false);
  const [sampleUrl, setSampleUrl] = useState('');
  const navigate = useNavigate();

  const countries = [
    "Global",
    "International",
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor (Timor-Leste)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];


  useEffect(() => {
    const fetchCategories = async () => {
      const querySnapshot = await getDocs(prServicesCatsDataCollectionRef);
      const categoriesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setCategories(categoriesData);
    };

    fetchCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await addDoc(prServicesDataCollectionRef, {
        name,
        category: categories.find(cat => cat.id === category)?.name || category,
        site_cat: category, 
        price,
        status,
        da,
        pa,
        tat,
        url,
        traffic: parseInt(traffic, 10) || 0,
        region,
        dofollow,
        sample_url: sampleUrl,

      });
      navigate('/admin/pr-services');
    } catch (error) {
      console.log('Error adding PR service: ', error);
    }
  };

  return (
    <div className="page-container">
      <h1>Add PR Service</h1>
      <form onSubmit={handleSubmit} className="user-edit-form">
       
        <div className="form-group">
          <label htmlFor="name" className="form-label">Publication Name:</label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="form-control"
            required
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="category" className="form-label">Category:</label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="form-control"
            required
          >
            <option value="">Select a Category</option>
            {categories.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="price" className="form-label">Selling Price:</label>
          <input
            id="price"
            type="text"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="form-control"
            required
          />
        </div>

        <div className="form-group form-check">
          <input
            type="checkbox"
            checked={status}
            onChange={(e) => setStatus(e.target.checked)}
            className="form-check-input"
            id="status"
          />
          <label htmlFor="status" className="form-check-label">Active Status</label>
        </div>

        <div className="form-group">
          <label htmlFor="da" className="form-label">DA (Domain Authority):</label>
          <input
            id="da"
            type="text"
            value={da}
            onChange={(e) => setDa(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="pa" className="form-label">PA (Page Authority):</label>
          <input
            id="pa"
            type="text"
            value={pa}
            onChange={(e) => setPa(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="tat" className="form-label">TAT (Turn Around Time):</label>
          <input
            id="tat"
            type="text"
            value={tat}
            onChange={(e) => setTat(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="url" className="form-label">URL:</label>
          <input
            id="url"
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="traffic" className="form-label">Traffic (Monthly Visits):</label>
          <input
            id="traffic"
            type="number"
            value={traffic}
            onChange={(e) => setTraffic(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="region" className="form-label">Region:</label>
          <select
            id="region"
            value={region}
            onChange={(e) => setRegion(e.target.value)}
            className="form-control"
            required
          >
            <option value="">Select a Region</option>
            {countries.map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group form-check">
          <input
            type="checkbox"
            checked={dofollow}
            onChange={(e) => setDofollow(e.target.checked)}
            className="form-check-input"
            id="dofollow"
          />
          <label htmlFor="dofollow" className="form-check-label">Dofollow</label>
        </div>

        <div className="form-group">
          <label htmlFor="sampleUrl" className="form-label">Sample URL:</label>
          <input
            id="sampleUrl"
            type="text"
            value={sampleUrl}
            onChange={(e) => setSampleUrl(e.target.value)}
            className="form-control"
          />
        </div>



        <div className="form-actions">
          <button type="submit" className="btn-save">Add Service</button>
        </div>
      </form>
    </div>
  );
}

export default AddPrServicePage;
