import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ordersCollectionRef } from '../../../firebase/firestore/collections';
import { getLocalApiLinks } from '../../../utils/localStorage/apiLinks';

function EditOrderPage() {
  const params = useParams();

  const divRef = useRef(null);

  const [order, setOrder] = useState(null);
  const [articleWritingRequested, setArticleWritingRequested] = useState(false);
  const [questionnaire, setQuestionnaire] = useState([]);

  const [status, setStatus] = useState("");
  const [publishedURL, setPublishedURL] = useState("");
  const [apis, setApis] = useState({});

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const orderDoc = doc(ordersCollectionRef, params.orderId);
        const orderSnapshot = await getDoc(orderDoc);

        if (orderSnapshot.exists()) {
          const orderData = orderSnapshot.data();
          setOrder({
            ...orderData,
            orderDate: orderData.orderDate.toDate().toISOString().split("T")[0], // Format date
            // Add any other necessary transformations here
          });
          setStatus(orderData.status);
          setPublishedURL(orderData.published_url);
          setArticleWritingRequested(orderData.articleWritingRequested);

          if (orderData.articleWritingRequested) {
            fetchQuestionnaire();
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error getting document:", error);
      }
    };

    const fetchApis = async () => {
      try {
        const apiLinks = await getLocalApiLinks();

        setApis(apiLinks);
      } catch (error) {
        console.error("Error fetching api links:", error);
      }
    };

    fetchOrder();
    fetchApis();
  }, [params.orderId]);

  const fetchQuestionnaire = async () => {
    try {
      const orderDoc = doc(ordersCollectionRef, params.orderId);
      const userResponseDocRef = doc(orderDoc, "userResponses", "data");

      // Fetch the data of the "data" document
      const userResponseDocSnapshot = await getDoc(userResponseDocRef);

      // Check if the "data" document exists
      if (userResponseDocSnapshot.exists()) {
        const userResponseData = userResponseDocSnapshot.data();

        setQuestionnaire(userResponseData.questions);
      } else {
        console.log("No 'data' document found in userResponses subcollection!");
      }
    } catch (error) {
      console.error("Error fetching questionnaire:", error);
    }
  };

  const notifyClient = async () => {
    try {
      // Replace '/api/send-email' with your actual server endpoint
      let response = await fetch(apis.stripe_create_order_notify, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderId: params.orderId,
          userEmail: order.userEmail,
        }),
      });
      response = await response.json();
      console.log("notify response:", JSON.stringify(response));

      if (response.ok) {
        alert("Client has been notified.");
      } else {
        throw new Error("Failed to send notification.");
      }
    } catch (error) {
      console.log("Error notifying client:", error);
      alert("Error notifying client. Please try again.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const orderDoc = doc(ordersCollectionRef, params.orderId);

    let updatedFields = {
      published_url: publishedURL,
      status: status,
    };

    if (status === "Published" && !order.orderCompletedDate) {
      updatedFields.orderCompletedDate = new Date();
    }

    try {
      await updateDoc(orderDoc, updatedFields);
      alert("Order updated successfully!");
    } catch (error) {
      console.log("Error updating order:", error);
      alert("Error updating order. Please try again.");
    }
  };

  const handleCopyToClipboard = () => {
    const textToCopy =
      "As a publicist working closely with my client, I am excited about the prospect of creating an engaging news article that showcases their achievements, personality, and unique story. To ensure we capture the essence of their journey accurately, I've compiled a questionnaire that delves into various aspects of their life and career.\n\nI kindly request your assistance in transforming the information provided by my client into a captivating 500-word news article. This article will serve as a powerful tool to promote their image and accomplishments.\n\nBelow, you'll find a summary of key points to include in the article:\n\nIntroduction:\n\nProvide a brief overview of my client's background and profession.\nCapture the reader's attention with an intriguing hook.\nCareer Highlights:\n\nHighlight key achievements and milestones in my client's career.\nShowcase any awards, recognitions, or notable projects.\nPersonal Insights:\n\nShare personal anecdotes or insights that reveal the person behind the professional.\nHighlight any passions, hobbies, or philanthropic efforts.\nChallenges and Triumphs:\n\nDiscuss any challenges my client has faced and how they overcame them.\nEmphasize the resilience and determination that led to their success.\nIndustry Impact:\n\nExplore my client's impact on their industry or community.\nInclude testimonials or endorsements from colleagues or industry experts.\nFuture Endeavors:\n\nProvide a glimpse into my client's future plans and projects.\nConclude with an optimistic outlook on what lies ahead.\nPlease feel free to be creative and engaging in your writing, keeping in mind the target audience's interests. Additionally, if you require more details on any specific aspect, don't hesitate to let me know.";

    // Combine the static content with the dynamic questionnaire content
    const fullText = `${textToCopy}\n\nArticle Content:\n\n${questionnaire
      .map((item) => `Q: ${item.question}\nAns: ${item.answer}`)
      .join("\n\n")}`;

    // Create a temporary textarea element to copy the text
    const textArea = document.createElement("textarea");
    textArea.value = fullText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);

    alert("Content copied to clipboard, now you can paste it in ChatGPT!");
  };

  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <div className="order-edit-container">
      <h2 className="page-title">{order.publication} Order</h2>

      <hr />
      <br />
      <form onSubmit={handleSubmit}>
        <div className="order-details">
          <div className="detail-item">
            <span className="detail-label">User:</span>
            <span className="detail-value">
              <Link to={`/admin/edit-user/${order.user}`}>{order.user}</Link>
            </span>
          </div>
          <div className="detail-item">
            <span className="detail-label">Order ID:</span>
            <span className="detail-value">{params.orderId}</span>
          </div>
          <div className="detail-item">
            <span className="detail-label">Order Date:</span>
            <span className="detail-value">{order.orderDate}</span>
          </div>

          <div className="detail-item">
            <span className="detail-label">Amount Paid:</span>
            <span className="detail-value">${order.amount}</span>
          </div>
          <br />
          <hr />
          <br />

          <div className="detail-item">
            <img src={order.image} alt="Order" className="img-preview" />
          </div>

          <br />
          <hr />
          <br />

          {!articleWritingRequested ? (
            <div className="detail-item">
              <span className="detail-label">Article Content:</span>
              <a
                href={
                  order.article_content.startsWith("http://") ||
                  order.article_content.startsWith("https://")
                    ? order.article_content
                    : `http://${order.article_content}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {order.article_content}
              </a>
            </div>
          ) : (
            <>
              <button onClick={handleCopyToClipboard}>
                Click to Copy GPT Query
              </button>
              <br />
              <br /> <hr />
              <div ref={divRef}>
                <span className="detail-label">
                  <u>Article Content:</u>
                </span>
                <br />
                {questionnaire.map((item) => (
                  <div>
                    <p>
                      <strong>Q: {item.question}</strong>
                    </p>
                    <p>Ans: {item.answer}</p>
                  </div>
                ))}
              </div>
            </>
          )}

          <br />
          <hr />
          <br />

          <div className="detail-item">
            <span className="detail-label">Published URL:</span>
            <input
              type="text"
              value={publishedURL}
              onChange={(e) => setPublishedURL(e.target.value)}
              className="form-input"
            />
          </div>
          <div className="detail-item">
            <span className="detail-label">Status:</span>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="form-select"
            >
              <option value="Pending">Pending</option>
              <option value="In-progress">In-progress</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Published">Published</option>
            </select>
          </div>
        </div>

        <div className="form-button-bar">
          <button type="submit" >
            Update Order
          </button>
          <button
            type="button"
            className="notify-button"
            onClick={notifyClient}
          >
            Notify Client
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditOrderPage;
