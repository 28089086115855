import React from 'react'
import { NavLink, Link } from "react-router-dom";

function SideBar({isShowMenu}) {
  return (
    <section className={isShowMenu ? "main__left-nav show" : "main__left-nav"}>
      <Link to="/">
        <img
          className="main__left-nav--logo"
          src="../../boost-pr-logo.png"
          alt="logo"
        />
      </Link>

      <nav className="main__left-nav--nav">
        <h3 className="title">Account</h3>
        <NavLink to="/signup" className="nav-item">
          <i className="fa fa-user" aria-hidden="true"></i>
          Sign Up
        </NavLink>
        <NavLink to="/" className="nav-item">
          <i className="fa fa-credit-card-alt" aria-hidden="true"></i>
          Login
        </NavLink>
      </nav>
    </section>
  );
}

export default SideBar;
