import { getDoc } from "firebase/firestore";
import { apiLinksDocumentRef } from "../../firebase/firestore/collections";

export const getLocalApiLinks = async () => {
  try {
    const apiLinksSnapshot = await getDoc(apiLinksDocumentRef);
    const data = apiLinksSnapshot.data();
    
    return data;
  } catch (error) {
    console.error("Error getting api links:", error);
    return null;
  }
};
