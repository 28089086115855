import React, { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";

import { useAuth } from "../contexts/AuthContext";
import LeftNav from "../navbars/UserLeftNav";
import UserTopNav from "../navbars/UserTopNav";

import "../styles/Press.css";

function UserLayout() {
   const navigate = useNavigate();
  // const { logout } = useAuth();

  const [show, setShow] = useState(false);

  const toggleMenu = () => {
    setShow(prev => !prev);
  }

  return (
    <div className="dashboard_main">
      <LeftNav isShowMenu={show} />
      <div className="Main">
        <UserTopNav toggleMenu={toggleMenu} />
        <Outlet />
      </div>
    </div>
  );
}

export default UserLayout;
