import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, arrayUnion, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/firebase'; // Update with your actual path
import { getLocalApiLinks } from '../../utils/localStorage/apiLinks';
import { getLocalApiKeys } from '../../utils/localStorage/apiKeys';

function Ticket() {
  const { id } = useParams();
  const [ticket, setTicket] = useState(null);
  const [newReply, setNewReply] = useState("");
  const [apis, setApis] = useState({});
  const [apiKeys, setApiKeys] = useState({});

  useEffect(() => {
    const fetchTicket = async () => {
      const ticketRef = doc(db, "tickets", id);
      const docSnap = await getDoc(ticketRef);

      if (docSnap.exists()) {
        setTicket({ id: docSnap.id, ...docSnap.data() });
      } else {
        console.log("No such ticket!");
      }
    };

    const fetchApis = async () => {
      try {
        const apiLinks = await getLocalApiLinks();

        setApis(apiLinks);
      } catch (error) {
        console.error("Error fetching api links:", error);
      }
    };

    const fetchApiKeys = async () => {
      try {
        const apiKeys = await getLocalApiKeys();

        setApiKeys(apiKeys);
      } catch (error) {
        console.error("Error fetching api keys:", error);
      }
    };

    fetchTicket();
    fetchApis();
    fetchApiKeys();
  }, [id]);

  // Function to send email notification
  const sendEmailNotification = async (notificationType, ticketId) => {
    const apiEndpoint = apis.stripe_premail;
    const payload = {
      apikey: apiKeys.stripe_premail, // replace with your actual API key
      type: notificationType,
      ticketId: ticketId,
      userEmail: "", // userEmail is not needed for user_reply type
      userName: "", // userName is not needed for user_reply type
    };

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Email notification failed to send.");
      }

      // Handle the response from your PHP script
      const responseData = await response.text();
      console.log(responseData);
    } catch (error) {
      console.log("Failed to send email notification:", error);
    }
  };

  const confirmCloseTicket = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to close this ticket?"
    );

    if (userConfirmed) {
      markAsSolved();
    }
  };

  const handleReplySubmit = async () => {
    if (!newReply.trim()) {
      return;
    }

    const reply = {
      message: newReply,
      createdAt: Timestamp.now(),
      isAdmin: false, // Adjust based on user role
    };

    try {
      const ticketRef = doc(db, "tickets", id);
      await updateDoc(ticketRef, {
        replies: arrayUnion(reply),
      });

      setTicket({ ...ticket, replies: [...(ticket.replies || []), reply] });
      setNewReply("");

      // After updating the ticket with the new reply, send a notification to the admins
      await sendEmailNotification("user_reply", id);
    } catch (error) {
      console.log("Error submitting reply:", error);
    }
  };

  const markAsSolved = async () => {
    try {
      const ticketRef = doc(db, "tickets", id);
      await updateDoc(ticketRef, {
        status: "closed",
      });

      setTicket({ ...ticket, status: "closed" });
      // Optional: Redirect user back to support page
      // navigate('/support');
    } catch (error) {
      console.log("Error updating ticket status:", error);
    }
  };

  if (!ticket) {
    return <div>Loading...</div>;
  }

  return (
    <div className="card_container">
      <div>
        <div>
          <h1 className="title">Ticket Details</h1>
          {/* Ticket Information */}
          <div className="ticket-info">
            <p className="select_1">
              <strong>Subject:</strong> {ticket.subject}
            </p>
            <p className="select_1">
              <strong>Status:</strong> #{ticket.id} |{" "}
              {ticket.createdAt?.toDate().toLocaleString()} |{" "}
              <strong>{ticket.status} Ticket </strong>
            </p>
            <p className="select_1">
              <strong>Message:</strong> {ticket.message}
            </p>
          </div>

          <hr className="divider" />
          <br />

          {/* Replies Section */}
          <div className="ticket-replies">
            <h1 className='title'>Replies</h1>
            {ticket.replies &&
              ticket.replies.length > 0 &&
              ticket.replies.map((reply, index) => (
                <div
                  key={index}
                  className={`reply ${
                    reply.isAdmin ? "admin-reply" : "user-reply"
                  }`}
                >
                  <p className="select_1">
                    <strong>
                      {reply.isAdmin
                        ? "Admin"
                        : `${ticket.user_first_name} ${ticket.user_last_name}`}
                      :
                    </strong>{" "}
                    {reply.message}
                  </p>

                  <p className="reply-time">
                    <em>{reply.createdAt.toDate().toLocaleString()}</em>
                  </p>
                </div>
              ))}
          </div>

          {/* Reply Form */}
          <div style={{ marginTop: "20px" }}>
            {ticket.status !== "closed" && (
              <div className="reply-form">
                <textarea
                  value={newReply}
                  onChange={(e) => setNewReply(e.target.value)}
                  placeholder="Write your reply here..."
                />
                <button onClick={handleReplySubmit}>Send Reply</button>
              </div>
            )}
          </div>

          {/* Mark As Solved Button */}
          {ticket.status !== "closed" && (
            <button className="mark-solved-button" onClick={confirmCloseTicket}>
              Close Ticket
            </button>
          )}

          {/* Display message if ticket is closed */}
          {ticket.status === "closed" && (
            <p className="ticket-closed-message select_1">
              This ticket has been closed and cannot receive new replies.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Ticket;
