import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {MdModeEdit} from "react-icons/md";

import { query, orderBy, getDocs, getDoc, doc, updateDoc} from 'firebase/firestore';
import { ordersCollectionRef, usersCollectionRef  } from '../../../firebase/firestore/collections';

function OrdersPage() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const navigate = useNavigate();

    // Function to calculate the count of orders for a specific status
    const getOrderStatusCount = (status) => {
      return orders.filter(order => order.status === status).length;
    };
  
    const pendingCount = getOrderStatusCount('Pending');
    const inProgressCount = getOrderStatusCount('In-Progress');
    const cancelledCount = getOrderStatusCount('Cancelled');
    const publishedCount = getOrderStatusCount('Published');


  const cancelAndRefundOrder = async (orderId, userId, refundAmount) => {
    if (window.confirm('Are you sure you want to cancel this order and refund the amount?')) {
      setLoading(true);
      try {
        // Ensure refundAmount is a number
        const refundAmountNumber = Number(refundAmount);
        if (isNaN(refundAmountNumber)) {
          throw new Error('Refund amount must be a number');
        }
  
        const orderDocRef = doc(ordersCollectionRef, orderId);
        await updateDoc(orderDocRef, { status: 'Cancelled' });
  
        const userDocRef = doc(usersCollectionRef, userId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          // Make sure to handle both existing balance and no balance scenarios
          let currentBalance = userDoc.data().balance;
          currentBalance = typeof currentBalance === 'number' ? currentBalance : 0;
          
          // Calculate new balance
          const newBalance = currentBalance + refundAmountNumber;
          await updateDoc(userDocRef, { balance: newBalance });
        } else {
          console.log("User document not found");
        }
  
        // Update the local state
        setOrders(orders.map((order) => {
          if (order.orderID === orderId) {
            return { ...order, status: 'Cancelled' };
          }
          return order;
        }));
        
      } catch (error) {
        console.log("Error processing refund: ", error);
      } finally {
        setLoading(false);
      }
    }
  };
  

  const updateOrderStatus = async (orderId) => {
    setLoading(true);
    try {
      const orderDocRef = doc(ordersCollectionRef, orderId);
  
      await updateDoc(orderDocRef, {status: 'In-Progress'});
  
      setOrders(orders.map((order) => {
        if (order.orderID === orderId) {
          return { ...order, status: 'In-Progress' };
        }
        return order;
      }));
    } catch (error) {
      console.log("Error updating order status: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        // Construct a query against the collection.
        const ordersQuery = query(ordersCollectionRef, orderBy('orderDate', 'desc'));
        const querySnapshot = await getDocs(ordersQuery);

        const fetchedOrders = querySnapshot.docs.map((doc) => ({
          orderID: doc.id,
          userID: doc.data().user,
          username: doc.data().username,
          publication: doc.data().publication,
          amount: doc.data().amount,
          orderDate: doc.data().orderDate.toDate().toISOString().split('T')[0],
          status: doc.data().status,
        }));

        setOrders(fetchedOrders);
      } catch (error) {
        console.log("Error fetching orders: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);


  function AdminOrderHistoryTable({ data }) {
    const handleCopyToClipboard = (orderID) => {
      // Get the host from the current URL
      const host = window.location.host;
      const linkToCopy = `${window.location.protocol}//${host}/order/${orderID}`;
  
      // Create a temporary textarea element to copy the text
      const textArea = document.createElement('textarea');
      textArea.value = linkToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
  
      alert('Link copied to clipboard: ' + linkToCopy);
    };
  
    if (!data.length > 0) {
      return <p>No Data Available</p>;
    }
  
    return (
      <div className='table-container'>
        <table className='custom-table'>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>User</th>
              <th>Publication</th>
              <th>Order Date</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((order) => (
              <tr key={order.orderID}>
                <td>{order.orderID}</td>
                <td data-label="User">
                  <Link to={`/admin/edit-user/${order.userID}`}>
                    {order.username}
                  </Link>
                </td>
                <td>{order.publication}</td>
                <td>{order.orderDate}</td>
                <td>{order.status}</td>
                <td>
                  <button onClick={() => navigate(`/admin/edit-order/${order.orderID}`)}>
                    <MdModeEdit />
                    </button>{" "}
                  {order.status !== 'In-Progress' && order.status !== 'Cancelled' && order.status !== 'Published' && (
                    <button onClick={() => updateOrderStatus(order.orderID)}>In-Progress</button>
                  )}{" "}
                  {order.status !== 'Cancelled' && order.status !== 'Published' && (
                    <button onClick={() => cancelAndRefundOrder(order.orderID, order.userID, order.amount)}>Cancel & Add Balance</button>
                  )}{" "}
                  <button onClick={() => handleCopyToClipboard(order.orderID)}>Share</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    );
  }
  
  

  const filteredOrders = orders.filter(order => {
    if (filter === 'all') return true;
    return order.status === filter;
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-container">
      <div className="status-cards-container">
        {/* Display total counts in cards */}
        <div className="card status-card">
          <h3>Pending Orders</h3>
          <p>{pendingCount}</p>
        </div>
        <div className="card status-card">
          <h3>In-Progress Orders</h3>
          <p>{inProgressCount}</p>
        </div>
        <div className="card status-card">
          <h3>Cancelled Orders</h3>
          <p>{cancelledCount}</p>
        </div>
        <div className="card status-card">
          <h3>Published Orders</h3>
          <p>{publishedCount}</p>
        </div>
      </div><br/>



      <div>
       
        <select className="form-select" value={filter} onChange={(e) => setFilter(e.target.value)}>
          <option value="all">All</option>
          <option value="Pending">Pending</option>
          <option value="In-Progress">In-Progress</option>
          <option value="Cancelled">Cancelled</option>
          <option value="Published">Published</option>
        </select>
      </div><br/>
      <AdminOrderHistoryTable data={filteredOrders} />
    </div>
  );
}

export default OrdersPage;
