import React, {useEffect, useState} from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import "../styles/Login.css";

function AuthLayout() {
  const navigate = useNavigate();
  const location = useLocation();

  const [siteInfo, setSiteInfo] = useState({
    logo: "",
    title: "",
  });

  useEffect(() => {
    getSiteInfo();
  }, []);

  const getSiteInfo = async () => {
    try {
      await fetch("config.json").then(async (res) => {
        const data = await res.json();
        setSiteInfo({
          logo: data.logo,
          title: data.title,
        });
      });
    } catch (error) {
      console.log("error getting site details", error);
    }
  };

  const navigateToLogin = () => navigate("/");
  const navigateToSignup = () => navigate("/signup");

  return (
    <div className="boost">
      <div className="boost_1">
        <img src={"/assets/images/up.png"} alt="Logo" className="up" />
        <img src={"/assets/images/bottom.png"} alt="Logo" className="bottom" />
        <img src={"/assets/images/mob-t.png"} alt="Logo" className="up mt" />
        <img
          src={"/assets/images/mob-b.png"}
          alt="Logo"
          className="bottom mt"
        />
        <div className="header-container">
          <div>
            <img src={siteInfo.logo} alt="Logo" className="logo" />
            <h1 className="boost_2">{siteInfo.title}</h1>
          </div>
        </div>
      </div>
      <div className="boost_3">
        <div className="boost_4">
          <div className="boost_5" onClick={navigateToLogin}>
            <h1
              className={`login ${location.pathname === "/" ? "border-b" : ""}`}
            >
              Login
            </h1>
          </div>
          <div className="boost_6" onClick={navigateToSignup}>
            <h1
              className={`signup ${
                location.pathname === "/signup" ? "border-b" : ""
              }`}
            >
              Sign Up
            </h1>
          </div>
        </div>
        <div className="boost_10">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AuthLayout;