import React, { useState } from "react";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "../contexts/AuthContext";

const navPages = [
  {
    pageName: "Buy Press",
    path: "/user/order",
    icon: (isActive) => (
      <svg
        width={21}
        height={21}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.9088 2.15869C16.743 2.15869 18.343 3.76619 18.3513 6.60036L18.3513 14.3837C18.3513 17.217 16.743 18.8254 13.9088 18.8254L6.1263 18.8254C3.29213 18.8254 1.68463 17.217 1.68463 14.3837L1.68463 6.60036C1.68463 3.76619 3.29213 2.15869 6.1263 2.15869L13.9088 2.15869ZM10.4346 5.60036C10.2005 5.45869 9.91713 5.45869 9.69297 5.60036C9.46713 5.74119 9.34297 6.00036 9.36713 6.25869L9.36713 14.7504C9.40963 15.1087 9.7088 15.3754 10.0588 15.3754C10.418 15.3754 10.7171 15.1087 10.7505 14.7504L10.7505 6.25869C10.7838 6.00036 10.6596 5.74119 10.4346 5.60036ZM6.54296 8.33369C6.31797 8.19202 6.0338 8.19202 5.80963 8.33369C5.5838 8.47536 5.45963 8.73286 5.48463 8.99203L5.48463 14.7504C5.51713 15.1087 5.81713 15.3754 6.17546 15.3754C6.53463 15.3754 6.8338 15.1087 6.86713 14.7504L6.86713 8.99203C6.89296 8.73286 6.76713 8.47536 6.54296 8.33369ZM14.2588 11.3587C14.0346 11.217 13.7513 11.217 13.518 11.3587C13.2921 11.5004 13.168 11.7495 13.2013 12.017L13.2013 14.7504C13.2346 15.1087 13.5338 15.3754 13.893 15.3754C14.243 15.3754 14.5421 15.1087 14.5846 14.7504L14.5846 12.017C14.6088 11.7495 14.4846 11.5004 14.2588 11.3587Z"
          fill={isActive ? "#22F15D" : "#A7A7A7"}
        />
      </svg>
    ),
  },
  {
    pageName: "Services",
    path: "/user/services",
    icon: (isActive) => (
      <svg
        width={21}
        height={21}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.9056 2.18885C15.1044 2.11393 16.2865 2.53016 17.1773 3.34599C17.9931 4.23673 18.4093 5.41884 18.3427 6.62593L18.3427 14.4012C18.4177 15.6083 17.9931 16.7904 17.1856 17.6812C16.2948 18.497 15.1044 18.9132 13.9056 18.8383L6.13026 18.8383C4.92317 18.9132 3.74105 18.497 2.8503 17.6812C2.03447 16.7904 1.61823 15.6083 1.69315 14.4012L1.69315 6.62593C1.61823 5.41884 2.03447 4.23673 2.8503 3.34599C3.74105 2.53016 4.92317 2.11393 6.13026 2.18885L13.9056 2.18885ZM13.6892 5.99325C13.173 5.47711 12.3406 5.47711 11.8244 5.99325L11.2667 6.55933C11.1834 6.64258 11.1834 6.7841 11.2667 6.86734C11.2667 6.86734 11.283 6.88358 11.3121 6.9125L11.5169 7.11614C11.6353 7.23399 11.7831 7.38116 11.9316 7.52916L12.435 8.03232C12.54 8.13784 12.6097 8.20855 12.6153 8.21595C12.7068 8.31584 12.7651 8.44904 12.7651 8.59889C12.7651 8.89857 12.5237 9.14832 12.2157 9.14832C12.0742 9.14832 11.941 9.09004 11.8494 8.99847L10.4592 7.61657C10.3926 7.54997 10.276 7.54997 10.2094 7.61657L6.23849 11.5875C5.96377 11.8622 5.8056 12.2285 5.79727 12.6197L5.74732 14.5927C5.74732 14.7009 5.78062 14.8008 5.85555 14.8757C5.93047 14.9506 6.03037 14.9923 6.13859 14.9923H8.09491C8.4945 14.9923 8.87744 14.8341 9.16881 14.5511L14.7714 8.93187C15.2792 8.41574 15.2792 7.58327 14.7714 7.07546L13.6892 5.99325Z"
          fill={isActive ? "#22F15D" : "#A7A7A7"}
        />
      </svg>
    ),
  },
  {
    pageName: "My Orders",
    path: "/user/order-history",
    icon: (isActive) => (
      <svg
        width={21}
        height={21}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.62246 11.7629C7.79827 11.7629 8.74059 12.7144 8.74059 13.8986V16.7414C8.74059 17.9172 7.79827 18.8762 6.62246 18.8762H3.80384C2.63636 18.8762 1.6857 17.9172 1.6857 16.7414L1.6857 13.8986C1.6857 12.7144 2.63636 11.7629 3.80384 11.7629L6.62246 11.7629ZM16.2459 11.7629C17.4134 11.7629 18.364 12.7144 18.364 13.8986V16.7414C18.364 17.9172 17.4134 18.8762 16.2459 18.8762H13.4273C12.2514 18.8762 11.3091 17.9172 11.3091 16.7414L11.3091 13.8986C11.3091 12.7144 12.2514 11.7629 13.4273 11.7629H16.2459ZM6.62246 2.19821C7.79827 2.19821 8.74059 3.15721 8.74059 4.33386V7.17667C8.74059 8.36082 7.79827 9.31148 6.62246 9.31148H3.80384C2.63636 9.31148 1.6857 8.36082 1.6857 7.17667L1.6857 4.33386C1.6857 3.15721 2.63636 2.19821 3.80384 2.19821L6.62246 2.19821ZM16.2459 2.19821C17.4134 2.19821 18.364 3.15721 18.364 4.33386V7.17667C18.364 8.36082 17.4134 9.31148 16.2459 9.31148H13.4273C12.2514 9.31148 11.3091 8.36082 11.3091 7.17667L11.3091 4.33386C11.3091 3.15721 12.2514 2.19821 13.4273 2.19821L16.2459 2.19821Z"
          fill={isActive ? "#22F15D" : "#A7A7A7"}
        />
      </svg>
    ),
  },
  {
    pageName: "Support",
    path: "/user/support",
    icon: (isActive) => (
      <svg
        width={21}
        height={21}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0345 2.21895C14.7679 2.21895 18.3512 6.09977 18.3512 10.5398C18.3512 15.6892 14.1512 18.8856 10.0179 18.8856C8.65121 18.8856 7.13454 18.5184 5.91787 17.8007C5.49287 17.5419 5.13454 17.35 4.67621 17.5002L2.99287 18.001C2.56787 18.1345 2.18454 17.8007 2.30954 17.35L2.86787 15.4805C2.95954 15.2218 2.94287 14.9464 2.80954 14.7294C2.09287 13.4107 1.68454 11.9669 1.68454 10.5648C1.68454 6.17488 5.19287 2.21895 10.0345 2.21895ZM13.8429 9.50488C13.2512 9.50488 12.7762 9.98059 12.7762 10.5731C12.7762 11.1574 13.2512 11.6414 13.8429 11.6414C14.4345 11.6414 14.9095 11.1574 14.9095 10.5731C14.9095 9.98059 14.4345 9.50488 13.8429 9.50488ZM10.0012 9.50488C9.41787 9.49653 8.93454 9.98059 8.93454 10.5648C8.93454 11.1574 9.40954 11.6331 10.0012 11.6414C10.5929 11.6414 11.0679 11.1574 11.0679 10.5731C11.0679 9.98059 10.5929 9.50488 10.0012 9.50488ZM6.15954 9.50488C5.56787 9.50488 5.09287 9.98059 5.09287 10.5731C5.09287 11.1574 5.57621 11.6414 6.15954 11.6414C6.75121 11.6331 7.22621 11.1574 7.22621 10.5731C7.22621 9.98059 6.75121 9.50488 6.15954 9.50488Z"
          fill={isActive ? "#22F15D" : "#A7A7A7"}
        />
      </svg>
    ),
  },
  {
    pageName: "My Profile",
    path: "/user/profile-update",
    icon: (isActive) => (
      <svg
        width={21}
        height={21}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.6174 2.2451C11.2477 2.2451 11.8182 2.5951 12.1333 3.11177C12.2866 3.36177 12.3888 3.6701 12.3633 3.9951C12.3463 4.2451 12.4229 4.4951 12.5592 4.72844C12.9935 5.43677 13.9558 5.70344 14.7053 5.30344C15.5484 4.8201 16.6129 5.11177 17.0984 5.93677L17.669 6.9201C18.1629 7.7451 17.8904 8.80343 17.0387 9.27843C16.3149 9.70343 16.0594 10.6451 16.4937 11.3618C16.63 11.5868 16.7833 11.7784 17.0217 11.8951C17.3198 12.0534 17.5497 12.3034 17.7115 12.5534C18.0266 13.0701 18.0011 13.7034 17.6945 14.2618L17.0984 15.2618C16.7833 15.7951 16.1956 16.1284 15.591 16.1284C15.2929 16.1284 14.9608 16.0451 14.6882 15.8784C14.4668 15.7368 14.2113 15.6868 13.9388 15.6868C13.0957 15.6868 12.3888 16.3784 12.3633 17.2034C12.3633 18.1618 11.5798 18.9118 10.6004 18.9118H9.44219C8.4543 18.9118 7.6708 18.1618 7.6708 17.2034C7.65377 16.3784 6.94691 15.6868 6.1038 15.6868C5.82276 15.6868 5.56727 15.7368 5.35436 15.8784C5.08184 16.0451 4.74119 16.1284 4.45163 16.1284C3.83846 16.1284 3.25083 15.7951 2.93573 15.2618L2.3481 14.2618C2.033 13.7201 2.01597 13.0701 2.33107 12.5534C2.46733 12.3034 2.72282 12.0534 3.01238 11.8951C3.25083 11.7784 3.40413 11.5868 3.54891 11.3618C3.97472 10.6451 3.71923 9.70343 2.99534 9.27843C2.15223 8.80343 1.87971 7.7451 2.36514 6.9201L2.93573 5.93677C3.42968 5.11177 4.4857 4.8201 5.33733 5.30344C6.07825 5.70344 7.04059 5.43677 7.47493 4.72844C7.61119 4.4951 7.68783 4.2451 7.6708 3.9951C7.65377 3.6701 7.74745 3.36177 7.90926 3.11177C8.22436 2.5951 8.79495 2.26177 9.41664 2.2451H10.6174ZM10.0298 8.22844C8.69276 8.22844 7.61119 9.27844 7.61119 10.5868C7.61119 11.8951 8.69276 12.9368 10.0298 12.9368C11.3669 12.9368 12.4229 11.8951 12.4229 10.5868C12.4229 9.27844 11.3669 8.22844 10.0298 8.22844Z"
          fill={isActive ? "#22F15D" : "#A7A7A7"}
        />
      </svg>
    ),
  },
  {
    pageName: "Payment Methods",
    path: "/user/manage-cards",
    icon: (isActive) => (
      <svg
        width={21}
        height={21}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.6345 2.26715C16.4595 2.26715 18.3512 4.25048 18.3512 7.20048L18.3512 14.0097C18.3512 16.9505 16.4595 18.9338 13.6345 18.9338L6.40954 18.9338C3.58454 18.9338 1.68454 16.9505 1.68454 14.0097L1.68454 7.20048C1.68454 4.25048 3.58454 2.26715 6.40954 2.26715L13.6345 2.26715ZM13.5012 8.10048C13.2179 7.81715 12.7512 7.81715 12.4679 8.10048L9.02621 11.5422L7.56787 10.0838C7.28454 9.80048 6.81787 9.80048 6.53454 10.0838C6.25121 10.3672 6.25121 10.8255 6.53454 11.1172L8.51787 13.0922C8.65954 13.2338 8.84287 13.3005 9.02621 13.3005C9.21787 13.3005 9.40121 13.2338 9.54287 13.0922L13.5012 9.13382C13.7845 8.85048 13.7845 8.39215 13.5012 8.10048Z"
          fill={isActive ? "#22F15D" : "#A7A7A7"}
        />
      </svg>
    ),
  },
];

function LeftNav() {
  const location = useLocation();
  const {logout} = useAuth();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [error, setError] = useState("");
  const navigate = useNavigate();

  async function handleLogout() {
    setError("");

    try {
      await logout();
    } catch (error) {
      setError("Failed to logout");
    }
  }

  const closeMenu = () => setIsMenuOpen(false);

  const handleToggleClick = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const navigateToPaymentMethodPage = () => navigate("/user/manage-cards")

  return (
    <>
      <div className="mobile_logo">
        <img src={"/assets/images/logo.png"} alt="Logo" className="logo" />
        <svg
          onClick={handleToggleClick}
          width="21"
          height="14"
          viewBox="0 0 21 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.16667 14H19.8333C20.475 14 21 13.475 21 12.8333C21 12.1917 20.475 11.6667 19.8333 11.6667H1.16667C0.525 11.6667 0 12.1917 0 12.8333C0 13.475 0.525 14 1.16667 14ZM1.16667 8.16667H19.8333C20.475 8.16667 21 7.64167 21 7C21 6.35833 20.475 5.83333 19.8333 5.83333H1.16667C0.525 5.83333 0 6.35833 0 7C0 7.64167 0.525 8.16667 1.16667 8.16667ZM0 1.16667C0 1.80833 0.525 2.33333 1.16667 2.33333H19.8333C20.475 2.33333 21 1.80833 21 1.16667C21 0.525 20.475 0 19.8333 0H1.16667C0.525 0 0 0.525 0 1.16667Z"
            fill="white"
          />
        </svg>
      </div>
      <div className={isMenuOpen ? "translateFull" : "super_main"}>
        <div className="sidebar_main">
          <Link to="/">
            <img
              src="/assets/images/logo.png"
              alt="logo"
              className="logo mob_logo"
            />
          </Link>

          <div style={{ marginTop: "50px" }}>
            {navPages.map((page) => {
              const isActive = (location.pathname + "/").startsWith(
                page.path + "/"
              );
              return (
                <NavLink
                  to={page.path}
                  key={page.path}
                  className={({ isActive }) =>
                    isActive ? "sidebar_list" : "sidebar_list_1"
                  }
                  onClick={closeMenu}
                >
                  {page.icon(isActive)}
                  <div className={isActive ? "list_1" : "list_2"}>
                    {page.pageName}
                  </div>
                  {isActive ? (
                    <svg
                      width={39}
                      height={41}
                      viewBox="0 0 39 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="19.5"
                        cy="20.7624"
                        rx="19.5"
                        ry="20.2402"
                        fill="black"
                      />
                      <path
                        d="M27.7071 22.5347C28.0976 22.1442 28.0976 21.5111 27.7071 21.1205L21.3431 14.7566C20.9526 14.366 20.3195 14.366 19.9289 14.7566C19.5384 15.1471 19.5384 15.7803 19.9289 16.1708L25.5858 21.8276L19.9289 27.4845C19.5384 27.875 19.5384 28.5082 19.9289 28.8987C20.3195 29.2892 20.9526 29.2892 21.3431 28.8987L27.7071 22.5347ZM12 22.8276H27V20.8276H12V22.8276Z"
                        fill="#22F15D"
                      />
                    </svg>
                  ) : null}
                </NavLink>
              );
            })}
          </div>
        </div>
        <div className="logout_main">
          <div className="funds">
            <img src={"/assets/images/funds.png"} alt="Funds" />
            <button className="fundsbtn" onClick={navigateToPaymentMethodPage}>
              Add Funds
            </button>
          </div>
          <div className="hr"></div>
          <div className="logout" onClick={handleLogout}>
            <svg
              width={19}
              height={22}
              viewBox="0 0 19 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.49586 1.85498C11.3591 1.85498 12.8787 3.62122 12.8787 5.79574V10.0471L8.29699 10.0471C7.96864 10.0471 7.70901 10.3489 7.70901 10.7306C7.70901 11.1033 7.96864 11.414 8.29699 11.414L12.8787 11.414L12.8787 15.6565C12.8787 17.831 11.3591 19.6061 9.48059 19.6061L5.7618 19.6061C3.89096 19.6061 2.37137 17.8399 2.37137 15.6654L2.37137 5.80461C2.37137 3.62122 3.89859 1.85498 5.76944 1.85498L9.49586 1.85498ZM14.7851 7.66866C15.0103 7.39352 15.378 7.39352 15.6032 7.65979L17.7947 10.2426C17.9073 10.3757 17.9673 10.5443 17.9673 10.7307C17.9673 10.9082 17.9073 11.0858 17.7947 11.21L15.6032 13.7928C15.4906 13.9259 15.3405 13.9969 15.1979 13.9969C15.0478 13.9969 14.8977 13.9259 14.7851 13.7928C14.56 13.5265 14.56 13.0916 14.7851 12.8254L15.986 11.4142L12.8788 11.4142V10.0473L15.986 10.0473L14.7851 8.6361C14.56 8.36983 14.56 7.93493 14.7851 7.66866Z"
                fill="white"
              />
            </svg>
            Logout
          </div>
        </div>
      </div>
    </>
  );
}

export default LeftNav;
