import React,  {useState} from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom';

import { useAuth } from '../contexts/AuthContext';

function AdminLayout() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const [error, setError] = useState("");

  async function handleLogout() {
    setError("");

    try {
      await logout();
    } catch (error) {
      setError("Failed to logout");
    }
  }

  return (
    <div className="admin-layout">
      <header className="header">
        <div className="logo">
          <span className="logo-text">
            <Link to="/admin/orders">
              <img
                // src="https://i.ibb.co/F3ftvwv/download.webp"
                src="./../boost-pr-logo.png"

                alt="Boost PR Logo"
              />
            </Link>
          </span>
        </div>
        <button className="logout-button" type="button" onClick={handleLogout}>
          logout
        </button>
      </header>
      <nav className="navbar">
        <ul>
          <li>
            <Link to={"/admin/user-management"}>User Management</Link>
          </li>
          <li>
            <Link to={"/admin/orders"}>PR Orders</Link>
          </li>
          <li>
            <Link to={"/admin/pr-services"}>PR Services</Link>
          </li>
          <li>
            <Link to={"/admin/transactions"}>Transactions</Link>
          </li>
          <li>
            <Link to={"/admin/manage-pages"}>Manage Pages</Link>
          </li>
          <li>
            <Link to={"/admin/support"}>Support</Link>
          </li>
          <li>
            <Link to={"/admin/sales"}>Sale</Link>
          </li>
        </ul>
      </nav>
      {error}
      <Outlet />
    </div>
  );
}

export default AdminLayout;
