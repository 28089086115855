import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { query, orderBy, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import { prServicesCatsDataCollectionRef } from '../../../firebase/firestore/collections';

function AddCategoryForm({ onAddCategory }) {
  const [categoryName, setCategoryName] = useState('');
  const [displayStatus, setDisplayStatus] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await onAddCategory({
      name: categoryName,
      displayStatus
    });
    setCategoryName('');
    setDisplayStatus(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        className="form-input"
        type="text"
        value={categoryName}
        onChange={(e) => setCategoryName(e.target.value)}
        placeholder="Category Name"
        required
      />
      <label>
        Display Status:
        <input
          type="checkbox"
          checked={displayStatus}
          onChange={(e) => setDisplayStatus(e.target.checked)}
        />
        <br />
        <br />
      </label>
      <button type="submit">Add Category</button>
    </form>
  );
}

function PRCategoriesTable({ categories, onEditCategory, onDeleteCategory }) {
  const handleDelete = (id) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this category?');
    if (isConfirmed) {
      onDeleteCategory(id);
    }
  };

  if (!categories.length > 0) {
    return <p>No Data Available</p>
  }

  return (

       <div className='table-container'>
      <table className='custom-table'>
      <thead>
        <tr>
          <th>ID</th>
          <th>Category Name</th>
          <th>Display Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {categories.map((category, index) => (
          
          <tr key={index}>
            <td>{category.id}</td>
            <td>{category.name}</td>
        
            <td>{category.displayStatus ? "True" : "False"}</td>
            <td>
              <button
                style={{ marginRight: "10px" }}
                onClick={() => onEditCategory(category.id)}
              >
                Edit
              </button>
              <button onClick={() => handleDelete(category.id)}>Delete</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  );
}

function PrServicesCategoriesPage() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const q = query(prServicesCatsDataCollectionRef, orderBy('name', 'asc'));
        const querySnapshot = await getDocs(q);

        const categoriesData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name,
          displayStatus: doc.data().status,
        }));

        setCategories(categoriesData);
      } catch (error) {
        console.log("Error fetching PR categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const addCategory = async (newCategory) => {
    try {
      await addDoc(prServicesCatsDataCollectionRef, newCategory);
      // Refetch the categories after adding a new one
      const q = query(prServicesCatsDataCollectionRef, orderBy('name', 'asc'));
      const querySnapshot = await getDocs(q);
      const categoriesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        displayStatus: doc.data().displayStatus,
      }));
      setCategories(categoriesData);
    } catch (error) {
      console.log("Error adding new category:", error);
    }
  };

  const deleteCategory = async (id) => {
    try {
      const docRef = doc(prServicesCatsDataCollectionRef, id);
      await deleteDoc(docRef);
      setCategories(categories.filter(category => category.id !== id));
    } catch (error) {
      console.log("Error deleting category:", error);
    }
  };

  function handleEdit(id) {
    navigate(`./edit/${id}`);
  }

  if (loading) {
    return <div>Loading categories...</div>;
  }

  return (
    <div className="page-container">
      <h1>PR Services Categories</h1>
      <AddCategoryForm onAddCategory={addCategory} />
      <br />
      <PRCategoriesTable
        categories={categories}
        onEditCategory={handleEdit}
        onDeleteCategory={deleteCategory}
      />
    </div>
  );
}

export default PrServicesCategoriesPage;
