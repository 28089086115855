import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { ordersCollectionRef } from '../firebase/firestore/collections';

function ViewOrderPage() {
  const params = useParams();

  const [order, setOrder] = useState(null);
  const [articleWritingRequested, setArticleWritingRequested] = useState(false);
  const [questionnaire, setQuestionnaire] = useState([]);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const orderDoc = doc(ordersCollectionRef, params.orderId);
        const orderSnapshot = await getDoc(orderDoc);

        if (orderSnapshot.exists()) {
          const orderData = orderSnapshot.data();
          setOrder({
            ...orderData,
            orderDate: orderData.orderDate.toDate().toISOString().split('T')[0], // Format date
            // Add any other necessary transformations here
          });
          setArticleWritingRequested(orderData.articleWritingRequested);

          if (orderData.articleWritingRequested) {
            fetchQuestionnaire();
          }
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.log('Error getting document:', error);
      }
    };

    fetchOrder();
  }, [params.orderId]);

  const fetchQuestionnaire = async () => {
    try {
      const orderDoc = doc(ordersCollectionRef, params.orderId);
      const userResponseDocRef = doc(orderDoc, "userResponses", "data");

      // Fetch the data of the "data" document
      const userResponseDocSnapshot = await getDoc(userResponseDocRef);

      // Check if the "data" document exists
      if (userResponseDocSnapshot.exists()) {
        const userResponseData = userResponseDocSnapshot.data();

        setQuestionnaire(userResponseData.questions);
      } else {
        console.log("No 'data' document found in userResponses subcollection!");
      }
    } catch (error) {
      console.error("Error fetching questionnaire:", error);
    }
  };

  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <div className="order-edit-container">
      <form>
        <div className="order-details">
          <div className="detail-item">
            <span className="detail-label">Order Date:</span>
            <span className="detail-value">{order.orderDate}</span>
          </div>
          <div className="detail-item">
            <span className="detail-label">Publication:</span>
            <span className="detail-value">{order.publication}</span>
          </div>
          <hr />
          <div className="detail-item">
            <img
              src={order.image}
              style={{ width: "100%" }}
              alt="Order"
              className="img-preview"
            />
          </div>
          <hr />
          <br />
          {!articleWritingRequested ? (
            <div className="detail-item">
              <span className="detail-label">Article Content:</span>
              <a
                href={
                  order.article_content.startsWith("http://") ||
                  order.article_content.startsWith("https://")
                    ? order.article_content
                    : `http://${order.article_content}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {order.article_content}
              </a>
            </div>
          ) : (
            <div>
              <span className="detail-label">Article Content:</span>
              {questionnaire.map((item) => (
                <div>
                  <p>Q: {item.question}</p>
                  <p>Ans: {item.answer}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default ViewOrderPage;
