import { useState, useEffect } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";

// pages
import DynamicPage from "./pages/DynamicPage";
import ViewOrderPage from "./pages/ViewOrder";
import NotFoundPage from "./pages/NotFound";
import MaintenanceMode from "./pages/MaintenanceMode";
import RefundPolicy from "./pages/RefundPolicy";

// auth pages
import SignupPage from "./pages/auth/Signup";
import LoginPage from "./pages/auth/Login";
import ForgotPasswordPage from "./pages/auth/ForgotPassword";
// user pages
import OrderPage from "./pages/user/order/Order";
import OrderHistoryPage from "./pages/user/order/OrderHistory";
import ServicesPage from "./pages/user/Services";
import ManageCardsPage from "./pages/user/ManageCards";
import ProfileUpdatePage from "./pages/user/profile/ProfileUpdate";
import UserSupportPage from "./pages/user/Support";
import Ticket from "./pages/user/Ticket";

// admin pages
import TransactionsPage from "./pages/admin/Transactions";
import OrdersPage from "./pages/admin/orders/Orders";
import EditOrderPage from "./pages/admin/orders/EditOrder";
import UserManagementPage from "./pages/admin/users/UserManagement";
import EditUserPage from "./pages/admin/users/EditUser";
import EditSalesPage from "./pages/admin/sale/Sales";
import PRServicesPage from "./pages/admin/pr-services/PRServices";
import AddPRServicesPage from "./pages/admin/pr-services/AddPRServices";
import UpdatePRServicesPage from "./pages/admin/pr-services/UpdatePRServices";
import EditPRServicePage from "./pages/admin/pr-services/EditPRService";
import PrServicesCategoriesPage from "./pages/admin/pr-services/PRServiceCategories";
import UpdatePRServiceCategoriesPage from "./pages/admin/pr-services/UpdatePRServiceCategories";
import EditPRServiceCategoryPage from "./pages/admin/pr-services/EditPRServiceCategory";
import ManagePagesPage from "./pages/admin/manage-pages/ManagePages";
import AddPage from "./pages/admin/manage-pages/AddPage";
import UpdatePage from "./pages/admin/manage-pages/UpdatePage";
import AdminSupportPage from "./pages/admin/Support";
import AdminTicket from "./pages/admin/Ticket";
// private route
import PrivateRoute from "./components/PrivateRoute";
// layouts
import AuthLayout from "./layouts/AuthLayout";
import UserLayout from "./layouts/UserLayout";
import AdminLayout from "./layouts/AdminLayout";
import DynamicPagesLayout from "./layouts/DynamicPagesLayout";

// context
import { useAuth } from "./contexts/AuthContext";

// utils
import { getDoc, getDocs, query, where } from "firebase/firestore";
import { apiKeysDocumentRef, apiLinksDocumentRef, dynamicPagesRef, servicesDocumentRef, siteSettingsDocumentRef } from "./firebase/firestore/collections";

// styles
import "./App.css";
import './css/font-awesome.css';
import './css/main.css';
import { saveSiteSettingsLocally } from "./utils/localStorage/siteSettings";

function App() {
  const { currentUser } = useAuth();

  const [pages, setPages] = useState([]);
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  useEffect(() => {
    fetchSiteSettings();
  }, []);

  async function fetchSiteSettings() {
    try {
      const siteSettingsSnapshot = await getDoc(siteSettingsDocumentRef);
      const servicesSnapshot = await getDoc(servicesDocumentRef);

      const data = {
        ...siteSettingsSnapshot.data(),
        ...servicesSnapshot.data(),
      };

      if (!maintenanceMode) {
        getDynamicPages();
      }

      setMaintenanceMode(data.maintenanceMode);
      saveSiteSettingsLocally(data);
    } catch (error) {
      console.log("error getting site settings", error);
    }
  }

  async function getDynamicPages() {
    try {
      const displayPagesQuery = query(
        dynamicPagesRef,
        where("display", "==", true)
      );
      const docsSnap = await getDocs(displayPagesQuery);

      let pages = [];
      docsSnap.docs.map((doc) => pages.push({ ...doc.data(), path: doc.id }));

      setPages(pages);
    } catch (error) {
      console.log("error getting dynamic pages", error);
    }
  }

  return (
    <>
      {maintenanceMode ? (
        <MaintenanceMode />
      ) : (
        <BrowserRouter>
          <Routes>
            {/* auth routes */}
            <Route
              element={
                <PrivateRoute
                  isAllowed={!currentUser}
                  redirectPath={
                    currentUser?.isAdmin ? "/admin/orders" : "user/order"
                  }
                />
              }
            >
              {/* auth pages */}
              <Route element={<AuthLayout />}>
                <Route path="" element={<LoginPage />} />
                <Route path="signup" element={<SignupPage />} />
                <Route
                  path="forgot-password"
                  element={<ForgotPasswordPage />}
                />
              </Route>
            </Route>
            {/* user routes */}
            <Route
              path="user"
              element={
                <PrivateRoute
                  isAllowed={currentUser && !currentUser?.isAdmin}
                />
              }
            >
              <Route element={<UserLayout />}>
                {/* user pages */}
                <Route path="order" element={<OrderPage />} />
                <Route path="order-history" element={<OrderHistoryPage />} />
                <Route path="services" element={<ServicesPage />} />
                <Route path="refundpolicy" element={<RefundPolicy />} />
                <Route path="manage-cards" element={<ManageCardsPage />} />
                <Route path="profile-update" element={<ProfileUpdatePage />} />
                <Route path="support" element={<UserSupportPage />} />
                <Route path="support/ticket/:id" element={<Ticket />} />

              </Route>
            </Route>
            {/* admin routes */}
            <Route
              path="admin"
              element={
                <PrivateRoute
                  isAllowed={currentUser && currentUser?.isAdmin}
                  redirectPath={currentUser ? "/user/order" : "/"}
                />
              }
            >
              <Route element={<AdminLayout />}>
                {/* admin pages */}
                <Route
                  path="user-management"
                  element={<UserManagementPage hello={"hi"} />}
                />
                <Route path="edit-user/:userId" element={<EditUserPage />} />
                <Route path="orders" element={<OrdersPage />} />
                <Route path="edit-order/:orderId" element={<EditOrderPage />} />
                <Route path="transactions" element={<TransactionsPage />} />
                <Route path="sales" element={<EditSalesPage />} />
                <Route path="pr-services" element={<PRServicesPage />} />
                <Route path="pr-services/add" element={<AddPRServicesPage />} />
                <Route
                  path="pr-services/update"
                  element={<UpdatePRServicesPage />}
                />
                <Route
                  path="pr-services/edit/:id"
                  element={<EditPRServicePage />}
                />
                <Route
                  path="pr-services/categories"
                  element={<PrServicesCategoriesPage />}
                />
                <Route
                  path="pr-services/categories/update"
                  element={<UpdatePRServiceCategoriesPage />}
                />
                <Route
                  path="pr-services/categories/edit/:id"
                  element={<EditPRServiceCategoryPage />}
                />
                <Route path="manage-pages" element={<ManagePagesPage />} />
                <Route path="manage-pages/add" element={<AddPage />} />
                <Route
                  path="manage-pages/update/:id"
                  element={<UpdatePage />}
                />
                <Route path="support" element={<AdminSupportPage />} />
                <Route path="support/ticket/:id" element={<AdminTicket />} />
              </Route>
            </Route>
            {/* dynamic routes */}
            <Route
              element={
                currentUser?.isAdmin ? (
                  <AdminLayout />
                ) : currentUser ? (
                  <UserLayout />
                ) : (
                  <DynamicPagesLayout />
                )
              }
            >
              <Route path="order/:orderId" element={<ViewOrderPage />} />
              {pages.map((page, index) => (
                <Route
                  key={index}
                  path={`${page.path}`}
                  element={<DynamicPage pageDetails={page} />}
                />
              ))}
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
