import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { usersCollectionRef, ordersCollectionRef, transactionsCollectionRef } from '../../../firebase/firestore/collections';

function EditUserPage() {
  const { userId } = useParams();
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    username: '',
    email: '',
    balance: 0,
    isAdmin: false,
  });
  const [billingDetails, setBillingDetails] = useState({
    addressLine1: '',
    addressLine2: '',
    city: '',
    country: '',
    region: '',
    zipCode: ''
  });
  const [transactions, setTransactions] = useState([]);
  const [orderCounts, setOrderCounts] = useState({ completed: 0, inProgress: 0, pending: 0,  cancelled: 0 });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const userDocRef = doc(usersCollectionRef, userId);
        const userSnapshot = await getDoc(userDocRef);

        if (userSnapshot.exists()) {
          setUserData(userSnapshot.data());
        } else {
          console.log('No such user!');
        }

        const billingDetailsRef = doc(userDocRef, 'BillingDetails', 'data');
        const billingDetailsSnapshot = await getDoc(billingDetailsRef);

        if (billingDetailsSnapshot.exists()) {
          setBillingDetails(billingDetailsSnapshot.data());
        } else {
          console.log('No billing details found!');
        }

        const transactionsQuery = query(transactionsCollectionRef, where("userid", "==", userId));
        const transactionsSnapshot = await getDocs(transactionsQuery);
        setTransactions(transactionsSnapshot.docs.map(doc => {
          const data = doc.data();
          // Convert Firestore timestamp to JavaScript Date object
          data.date = data.date?.toDate(); // assuming 'date' is the field with the timestamp
          return { id: doc.id, ...data };
        }));

        const ordersQuery = query(ordersCollectionRef, where("user", "==", userId));
        const ordersSnapshot = await getDocs(ordersQuery);
        const ordersStatusCounts = ordersSnapshot.docs.reduce((acc, doc) => {
          const status = doc.data().status;
          acc[status] = (acc[status] || 0) + 1;
          return acc;
        }, {});

        setOrderCounts({
          completed: ordersStatusCounts['Completed'] || 0,
          pending: ordersStatusCounts['Pending'] || 0,
          inProgress: ordersStatusCounts['In-Progress'] || 0,
          cancelled: ordersStatusCounts['Cancelled'] || 0,
        });

      } catch (error) {
        console.log('Error fetching data: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  const handleUserChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserData({
      ...userData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleBillingChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails({
      ...billingDetails,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userDocRef = doc(usersCollectionRef, userId);
      await updateDoc(userDocRef, userData);

      const billingDetailsRef = doc(userDocRef, 'BillingDetails', 'data');
      await updateDoc(billingDetailsRef, billingDetails);

      alert('User and billing details updated successfully!');
    } catch (error) {
      console.log('Error updating data: ', error);
      alert('Failed to update user and billing data.');
    } finally {
      setLoading(false);
    }
  };

  // Render part of the component
  if (loading) {
    return <div>Loading user data...</div>;
  }

  return (
    <div className="page-container">
      <div className="order-counts-wrapper">
        <div className="order-count-card">
          <p>Completed Orders: {orderCounts.completed}</p>
        </div>
        <div className="order-count-card">
          <p>Orders In-Progress: {orderCounts.inProgress}</p>
        </div>
        <div className="order-count-card">
          <p>Pending Orders: {orderCounts.pending}</p>
        </div>
        <div className="order-count-card">
          <p>Cancelled Orders: {orderCounts.cancelled}</p>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="user-edit-form">
        <div className="details-section">
          <h2>User Details</h2>
          {/* User Details Form */}
          {Object.entries(userData).map(([key, value]) => {
            const label = key.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase());
            return (
              <div className="form-group" key={key}>
                <label htmlFor={key}>{label}</label>
                {key === 'isAdmin' ? (
                  <input
                    id={key}
                    name={key}
                    type="checkbox"
                    checked={value}
                    onChange={handleUserChange}
                  />
                ) : (
                  <input
                    id={key}
                    name={key}
                    type={key === 'email' ? 'email' : 'text'}
                    value={value}
                    onChange={handleUserChange}
                    disabled={key === 'email'}
                  />
                )}
              </div>
            );
          })}
        </div>

        <div className="details-section">
  <h2>Billing Details</h2>
  {/* Billing Details Form */}
  {Object.entries(billingDetails).map(([key, value]) => (
    <div className="form-group" key={key}>
      <label htmlFor={key} className="form-label">
        {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:
      </label>
      <input
        id={key}
        name={key}
        type="text"
        className="form-control"
        value={value}
        onChange={handleBillingChange}
        placeholder={`Enter ${key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}`}
      />
    </div>
  ))}
</div>

        <div className="form-actions">
          <button type="submit" className="btn-save">Save Changes</button>
        </div>
      </form>

      {/* Transactions Table */}
      <h3>Transactions</h3>
      <div className='table-container'>
      <table className="custom-table">
        <thead>
          <tr>
            <th>Transaction ID</th>
            <th>Date</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {transactions.length > 0 ? (
            transactions.map(transaction => (
              <tr key={transaction.id}>
                <td>{transaction.id}</td>
                <td>{transaction.date instanceof Date ? transaction.date.toLocaleDateString() : 'N/A'}</td>
                <td>${transaction.amount}</td>
                <td>{transaction.status}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">No transactions for this user</td>
            </tr>
          )}
        </tbody>
      </table>
      </div>

    </div>
  );
}

export default EditUserPage;
