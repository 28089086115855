import React from 'react'

function RefundPolicy() {
  return (
    <div className="content card_container">

      <h1 className="title">Refund Policy</h1>
      <p className="select_1">Effective as of 07/11/2023</p>
      <br /><p className="select_1">We appreciate your business and strive to provide you with the best service in getting your articles published. Due to the nature of our business and the costs incurred to initiate your PR campaigns, we have adopted the following refund policy:</p>
      <br /><h1 className="title">Refund Eligibility Window:</h1>
      <br /><p className="select_1">Customers have a right to a full refund of their purchase within one (1) hour from the time of the transaction. This limited window reflects the immediate initiation of services and the costs associated with preparing and submitting your articles for publication.</p>
      <br /><h1 className="title">Refund Requests:</h1>
      <br /><p className="select_1">To request a refund, you must contact our customer service team within the one-hour window. You can do so by opening a support ticker with the subject line "Refund Request - [Your Order Number]." Please provide a detailed explanation for your request to assist us in processing your refund promptly.</p>
      <br /><h1 className="title">Non-Refundable Circumstances:</h1>
      <br /><p className="select_1">Once an hour has passed since your purchase, we are unable to offer a refund under any circumstances. This policy is strictly due to the following reasons:</p>
      <br />
      <p className="select_1">
        - Payments to Vendors: We are committed to paying our vendors promptly for the services they render to fulfill your order.
        <br /> - Non-Refundable Publications: Some publications do not offer refunds once an article has been submitted or published.
      </p>
      <br /><h1 className="title">Exceptions:</h1>
      <br /><p className="select_1">The only exception to this policy will be in the event that Boost PR fails to initiate the PR campaign within the promised time frame due to an error on our part. In such rare instances, the customer may be eligible for a refund, which will be assessed on a case-by-case basis.</p>
      <br /><h1 className="title">Processing Refunds:</h1>
      <br /><p className="select_1">Eligible refunds will be processed back to the original method of payment within a reasonable time frame, typically within 5-10 business days, depending on your bank or credit card processor.</p>
      <br /><h1 className="title">Modification of Refund Policy:</h1>
      <br /><p className="select_1">Boost PR reserves the right to modify this refund policy at any time, without prior notice, by updating this document. The latest version of our refund policy will always be available on our website.</p>
      <br /><h1 className="title">Contact Information:</h1>
      <br /><p className="select_1">If you have any questions or concerns about our refund policy, please open a ticket.</p>
      <br /><p className="select_1">We value your business and are dedicated to ensuring your satisfaction. By purchasing our services, you acknowledge that you have read, understand, and agree to the terms outlined in this refund policy.</p>
      <br /><p className="select_1">Thank you for choosing Boost PR.</p>
    </div>
  )
}

export default RefundPolicy