import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, arrayUnion, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { Link } from 'react-router-dom';
import { getLocalApiLinks } from '../../utils/localStorage/apiLinks';
import { getLocalApiKeys } from '../../utils/localStorage/apiKeys';

function AdminTicket() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [ticket, setTicket] = useState(null);
  const [newReply, setNewReply] = useState("");
  const [ticketStatus, setTicketStatus] = useState(""); // Renamed status to ticketStatus to avoid global conflict

  const [apis, setApis] = useState({});
  const [apiKeys, setApiKeys] = useState({});

  useEffect(() => {
    const fetchTicket = async () => {
      const docRef = doc(db, "tickets", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setTicket({ id: docSnap.id, ...data });
        setTicketStatus(data.status);
      } else {
        navigate("/admin/support");
      }
    };

    const fetchApis = async () => {
      try {
        const apiLinks = await getLocalApiLinks();

        setApis(apiLinks);
      } catch (error) {
        console.error("Error fetching api links:", error);
      }
    };

    const fetchApiKeys = async () => {
      try {
        const apiKeys = await getLocalApiKeys();

        setApiKeys(apiKeys);
      } catch (error) {
        console.error("Error fetching api keys:", error);
      }
    };

    fetchTicket();
    fetchApis();
    fetchApiKeys();
  }, [id, navigate]);

  const updateTicketStatus = async (newStatus) => {
    const ticketRef = doc(db, "tickets", id);
    try {
      await updateDoc(ticketRef, { status: newStatus });
      setTicket((prevTicket) => ({ ...prevTicket, status: newStatus }));
      setTicketStatus(newStatus);
    } catch (error) {
      console.log("Error updating ticket status:", error);
    }
  };

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    updateTicketStatus(newStatus);
  };

  const handleReplySubmit = async () => {
    if (!newReply.trim()) {
      return;
    }

    const reply = {
      message: newReply,
      createdAt: Timestamp.now(),
      isAdmin: true,
    };

    let newStatus = ticketStatus === "closed" ? "open" : ticketStatus;

    try {
      const ticketRef = doc(db, "tickets", id);
      await updateDoc(ticketRef, {
        replies: arrayUnion(reply),
        status: newStatus,
      });

      setTicket((prevTicket) => ({
        ...prevTicket,
        replies: [...(prevTicket.replies || []), reply],
        status: newStatus,
      }));
      setTicketStatus(newStatus);
      setNewReply("");

      sendEmailNotification(ticket);
    } catch (error) {
      console.log("Error submitting reply:", error);
    }
  };

  const sendEmailNotification = async (ticketData) => {
    const apiEndpoint = apis.stripe_premail;
    const payload = {
      apikey: apiKeys.stripe_premail,
      type: "admin_reply",
      ticketId: ticketData.id,
      userEmail: ticketData.userEmail,
      userName: ticketData.userName,
    };

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Email notification failed to send.");
      }

      const responseData = await response.text();
    } catch (error) {
      console.log("Failed to send email notification:", error);
    }
  };

  if (!ticket) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-container">
      <div className="admin-ticket-page">
        <div className="ticket-details">
          <h2>Ticket Details (Admin)</h2>

          {/* Buttons to quickly update ticket status */}
          <div className="ticket-status-buttons">
            <button onClick={() => updateTicketStatus("open")}>
              Reopen Ticket
            </button>
            <button onClick={() => updateTicketStatus("closed")}>
              Close Ticket
            </button>
            <button onClick={() => updateTicketStatus("user-reply")}>
              Mark as User Reply
            </button>
            <button onClick={() => updateTicketStatus("admin-reply")}>
              Mark as Admin Reply
            </button>
          </div>

          {/* Ticket Information */}
          <div className="ticket-info">
            <p>
              <strong>ID:</strong> {ticket.id}
            </p>
            <p>
              <strong>Subject:</strong> {ticket.subject}
            </p>
            <p>
              <strong>Message:</strong> {ticket.message}
            </p>
            <p>
              <strong>Status:</strong> {ticket.status}
            </p>
            <p>
              <strong>User:</strong>{" "}
              <Link to={`/admin/edit-user/${ticket.createdBy}`}>
                {ticket.user_first_name} {ticket.user_last_name}
              </Link>
            </p>
            <p>
              <strong>Created At:</strong>{" "}
              {ticket.createdAt?.toDate().toLocaleString()}
            </p>
            {ticket.userId && (
              <p>
                <strong>User:</strong>{" "}
                <a href={`/admin/edit-user/${ticket.userId}`}>Profile</a>
              </p>
            )}
            {ticket.orderId && (
              <p>
                <strong>Order ID:</strong>{" "}
                <a href={`/admin/edit-order/${ticket.orderId}`}>
                  {ticket.orderId}
                </a>
              </p>
            )}
          </div>

          {/* Replies Section */}
          <div className="ticket-replies">
            <h3>Replies</h3>
            {ticket.replies && ticket.replies.length > 0 ? (
              ticket.replies.map((reply, index) => (
                <div
                  key={index}
                  className={`reply ${
                    reply.isAdmin ? "admin-reply" : "user-reply"
                  }`}
                >
                  <p>
                    <strong>
                      {reply.isAdmin
                        ? "Admin"
                        : `${ticket.user_first_name} ${ticket.user_last_name}`}
                      :
                    </strong>
                    {reply.message}
                  </p>

                  <p className="reply-time">
                    <em>{reply.createdAt.toDate().toLocaleString()}</em>
                  </p>
                </div>
              ))
            ) : (
              <p>No replies yet.</p>
            )}
          </div>

          {/* Reply Form */}
          <div className="reply-form">
            <textarea
              value={newReply}
              onChange={(e) => setNewReply(e.target.value)}
              placeholder="Write your reply here..."
            />
            <button onClick={handleReplySubmit}>Send Reply</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminTicket;
