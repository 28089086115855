import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

function AddPage() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [display, setDisplay] = useState(false);
  const navigate = useNavigate();

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleDisplayChange = (event) => {
    setDisplay(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const docId = title.trim().toLowerCase().replace(/[\W_]+/g, '-');
      const pageRef = doc(db, 'pages', docId); 

      await setDoc(pageRef, {
        title: title,
        content: content,
        display: display,
      });
      navigate('/admin/manage-pages'); 
    } catch (error) {
      console.log('Error adding page:', error);
    }
  };



  const formGroupStyle = {
    marginBottom: '20px',
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '10px',
    fontWeight: 'bold',
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  };

  const quillContainerStyle = {
    height: '150px', // Set the height for the content field
  };

  const buttonStyle = {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#5cb85c',
    color: '#fff',
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '10px',
  };

  return (
    <div className='page-container '>
      <h2>Add Page</h2>
      <form onSubmit={handleSubmit}>
        <div style={formGroupStyle}>
          <label htmlFor="title" style={labelStyle}>Title:</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={handleTitleChange}
            placeholder="Page Title"
            required
            style={inputStyle}
          />
        </div>
        <div style={formGroupStyle}>
          <label htmlFor="content" style={labelStyle}>Content:</label>
          <div style={quillContainerStyle}>
            <ReactQuill theme="snow" value={content} onChange={handleContentChange} />
          </div>
        </div>
        <div style={formGroupStyle}>
          <label style={labelStyle} className='check_2'>
            <input
              type="checkbox"
              checked={display}
              onChange={handleDisplayChange}
            /> Display Page
          </label>
        </div>
        <button type="submit" style={buttonStyle}>Add Page</button>
      </form>
    </div>
  );
}

export default AddPage;
