import React, { useState, useEffect } from "react";
import { collection, query, getDocs, orderBy, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function SupportPage() {
  const [tickets, setTickets] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTickets();
  }, []);

  const fetchTickets = async () => {
    const ticketsCollectionRef = collection(db, "tickets");
    const q = query(ticketsCollectionRef, orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(q);
    setTickets(querySnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
      createdAt: doc.data().createdAt?.toDate().toLocaleString()
    })));
  };

  const handleViewTicket = (ticketId) => {
    // Navigate to the ticket detail page, assuming it's at `/admin/support/ticket/:id`
    navigate(`/admin/support/ticket/${ticketId}`);
  };

  const handleCloseTicket = async (ticketId) => {
    const ticketRef = doc(db, "tickets", ticketId);
    await updateDoc(ticketRef, { status: "closed" });
    fetchTickets();
  };

  const handleDeleteTicket = async (ticketId) => {
    if (window.confirm("Are you sure you want to delete this ticket?")) {
      const ticketRef = doc(db, "tickets", ticketId);
      await deleteDoc(ticketRef);
      fetchTickets();
    }
  };

  return (
    <div className="page-container">

      {tickets.length > 0 ? (

    <div className='table-container'>
      <table className='custom-table'>
          <thead>
            <tr>
              <th>User</th>
              <th>Subject</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((ticket) => (
              <tr key={ticket.id}>
                <td>
                  <Link to={`/admin/edit-user/${ticket.createdBy}`}>
                    {ticket.user_first_name} {ticket.user_last_name}
                  </Link>
                </td>

                <td>{ticket.subject}</td>
                <td>{ticket.status}</td>
                <td>{ticket.createdAt}</td>
                <td>
                  <button onClick={() => handleViewTicket(ticket.id)}>
                    View
                  </button>{" "}
                  {ticket.status !== "closed" && (
                    <button onClick={() => handleCloseTicket(ticket.id)}>
                      Close
                    </button>
                  )}{" "}
                  <button onClick={() => handleDeleteTicket(ticket.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      ) : (
        <p>No Data Available</p>
      )}
    </div>
  );
}

export default SupportPage;
