import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const LoginVerificationModal = ({
  isOpen,
  onRequestClose,
  onResendVerification,
  emailResent,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Login Verification Modal"
    >
      <h2>Email Not Verified</h2>
      <p className="select_1">Your email is not verified. Please verify your email to continue.</p>
      <button type="button" onClick={onResendVerification}>Resend Verification Email</button>
      {emailResent && <p className="select_1">Email has been resent!</p>}
      <button onClick={onRequestClose}>Close</button>
    </Modal>
  );
};

export default LoginVerificationModal;
