import { collection, doc } from "firebase/firestore";
import { db } from "../firebase";

const dynamicPagesRef = collection(db, "pages");
const usersCollectionRef = collection(db, 'users');
const ordersCollectionRef = collection(db, 'orders');
const transactionsCollectionRef = collection(db, 'transactions');
const pagesCollectionRef = collection(db, 'pages');

const prSitesCollectionRef = collection(db, 'prsites');
const sitesDocumentRef = doc(prSitesCollectionRef, 'sites');
const sitesCatsDocumentRef = doc(prSitesCollectionRef, 'categories');
const prServicesDataCollectionRef = collection(sitesDocumentRef, 'data');
const prServicesCatsDataCollectionRef = collection(sitesCatsDocumentRef, 'data');

// admin settings
const ADMIN_SETTINGS_COLLECTION_REF = collection(db, "admin_control");
const saleDocumentRef = doc(ADMIN_SETTINGS_COLLECTION_REF, "sale");
const siteSettingsDocumentRef = doc(ADMIN_SETTINGS_COLLECTION_REF, "siteSettings");
const servicesDocumentRef = doc(ADMIN_SETTINGS_COLLECTION_REF, "services");
const apiLinksDocumentRef = doc(ADMIN_SETTINGS_COLLECTION_REF, "api_links");
const apiKeysCollectionRef = collection(apiLinksDocumentRef, "api_keys");
const apiKeysDocumentRef = doc(apiKeysCollectionRef, "data");

export { 
    dynamicPagesRef,
    usersCollectionRef,
    prServicesCatsDataCollectionRef,
    ordersCollectionRef,
    transactionsCollectionRef,
    prServicesDataCollectionRef,
    pagesCollectionRef,
    // admin
    saleDocumentRef,
    siteSettingsDocumentRef,
    servicesDocumentRef,
    apiLinksDocumentRef,
    apiKeysDocumentRef
};
