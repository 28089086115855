const STORAGE_KEY = "siteSettings";

export const saveSiteSettingsLocally = async (siteSettings) => {
  try {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(siteSettings));
  } catch (error) {
    console.error("Error saving site settings locally:", error)
  }
};

export const getLocalSiteSettings = () => {
  try {
    const localSiteSettings = localStorage.getItem(STORAGE_KEY);
    return localSiteSettings ? JSON.parse(localSiteSettings) : null;
  } catch (error) {
    console.error("Error getting local site settings:", error);
    return null;
  }
};
