import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const SignupModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Signup Verification Modal"
    >
      <h2>Please Verify Your Email</h2>
      <p>
        A verification email has been sent. Please verify your email to
        continue.
      </p>
      <button onClick={onRequestClose}>Close</button>
    </Modal>
  );
};

export default SignupModal;
