import React, { useContext, useEffect, useState } from 'react';
import CustomModal from '../components/modals/CustomModal';
import SignupModal from '../components/modals/SignupModal';
import LoginVerificationModal from '../components/modals/LoginVerificationModal';

import {auth} from "../firebase/firebase";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import { usersCollectionRef } from '../firebase/firestore/collections';
import { getDoc, doc } from 'firebase/firestore';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [userEmail, setUserEmail] = useState();
  const [emailResent, setEmailResent] = useState(false);

  // const [showLoginVerificationModal, setShowLoginVerificationModal] =
  //   useState(false);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    description: "",
    buttons: [],
  });

  const openModal = (title, description, buttons = []) => {
    setModalContent({
      title,
      description,
      buttons,
    });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  async function resendVerificationEmail(email) {
    try {
      const user = auth.currentUser;
      await sendEmailVerification(user);
      // console.log("Resent verification email to:", email);
    } catch (error) {
      console.error("Error resending verification email:", error.message);
    }
  }

  async function manuallySendVerificationEmail(email) {
    try {
      if (email) {
        await resendVerificationEmail(email);
        openModal("Email Sent", "Please check your mail and verify the email", [
          { text: "Close", onClick: closeModal },
        ]);
        //logout();
      }
    } catch (error) {
      console.log("manually send verification email error:", error);
    }
  };

  async function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password).then(
      (userCredential) => {
        const user = userCredential.user;

        if (user) {
          sendEmailVerification(user)
            .then(() => {
              console.log("Verification email sent.");
              setUserEmail(email);
              openModal(
                "Please Verify Your Email",
                "A verification email has been sent. Please verify your email to continue.",
                [{ text: "Close", onClick: closeModal }]
              );
              //logout(); //Removed the Logout function call to keep the user Logged in after account creation
            })
            .catch((error) => {
              console.log(error.message);
              openModal("Sign Up Failed", "Please try again later", [
                { text: "Close", onClick: closeModal },
              ]);
            });
        }

        return userCredential;
      }
    );
  }

  async function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        if (user) {
          setUserEmail(user.email);
          openModal(
            "Email Not Verified",
            "Your email is not verified. Please verify your email to continue.",
            [
              // { text: "Close", onClick: closeModal },
              { text: "Continue", onClick: closeModal },
              {
                text: "Resend Verification Email",
                onClick: () => manuallySendVerificationEmail(email),
              },
            ]
          );
        }

        return userCredential;
      })
  }

  function logout() {
    localStorage.clear();
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateCurrentUserData(data) {
    setCurrentUser((prev) => {
      return {
        ...prev,
        ...data,
      };
    });
  }


  useEffect(() => {

    const user = localStorage.getItem("user");
    if (user) {
      setCurrentUser(JSON.parse(user));
    }

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user != null) {
        const userDocRef = doc(usersCollectionRef, user.uid);
        const billingDetailsRef = doc(userDocRef, "BillingDetails", "data");
        const userSnapshot = await getDoc(userDocRef);
        const billinDetailsSnapshot = await getDoc(billingDetailsRef);

        if (userSnapshot && billinDetailsSnapshot) {
          const userData = {
            ...user,
            email: user.email, // Access email directly from user object
            ...userSnapshot.data(),
            billingDetails: billinDetailsSnapshot.data(),
          };
          setCurrentUser(userData);
          localStorage.setItem("user", JSON.stringify(userData));
        } else {
          console.error("User data or billing details not found.");
        }
      } else {
        setCurrentUser(null);
        localStorage.removeItem("user");
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);


  const value = {
    currentUser,
    signup,
    login,
    logout,
    resetPassword,
    updateCurrentUserData,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
      <CustomModal
        isOpen={modalIsOpen}
        title={modalContent.title}
        description={modalContent.description}
        buttons={modalContent.buttons}
        closeModal={closeModal}
      />
    </AuthContext.Provider>
  );
}
