import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { query, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { pagesCollectionRef } from '../../../firebase/firestore/collections'; 

function ManagePagesPage() {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPages = async () => {
      try {
        setLoading(true);
        const q = query(pagesCollectionRef);
        const querySnapshot = await getDocs(q);
        const pagesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPages(pagesData);
      } catch (error) {
        console.log("Error fetching pages:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPages();
  }, []);

  const handleDelete = async (pageId) => {
    if (window.confirm("Are you sure you want to delete this page?")) {
      try {
        await deleteDoc(doc(pagesCollectionRef, pageId));
        setPages(pages.filter((page) => page.id !== pageId));
      } catch (error) {
        console.log("Error deleting page:", error);
      }
    }
  };

  if (loading) {
    return <div>Loading pages...</div>;
  }

  return (
    <div className="page-container">

      <button onClick={() => navigate("./add")}>Add Page</button>
      <br/><br/>
      {pages.length > 0 ? (
        
      <div className='table-container'>
      
      <table className='custom-table'>
          <thead>
            <tr>
              <th>Title</th>
              <th>Display</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {pages.map((page) => (
              <tr key={page.id}>
                <td>{page.title}</td>
                <td>{page.display ? "Yes" : "No"}</td>
                <td>
                  <button onClick={() => navigate(`./update/${page.id}`)}>
                    Edit
                  </button>{" "}
                  <button onClick={() => handleDelete(page.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      ) : (
        <p>No Data Available</p>
      )}
    </div>
  );
}

export default ManagePagesPage;
