import React,{useState} from "react";
import { Link, NavLink } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import CustomModal from "../components/modals/CustomModal";
import { auth } from "../firebase/firebase";
import { sendEmailVerification } from "firebase/auth";
function UserTopNav() {
  const { currentUser, logout } = useAuth();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    description: "",
    buttons: [],
  });

  async function resendVerificationEmail(email) {
    try {

      const user = auth.currentUser;
      await sendEmailVerification(user);
      // console.log("Resent verification email to:", email);
    } catch (error) {
      console.error("Error resending verification email:", error.message);
    }
  }
  async function manuallySendVerificationEmail(email) {
    try {
      if (email) {

        await resendVerificationEmail(email);
        openModal("Email Sent", "Please check your mail and verify the email", [
          { text: "Close", onClick: closeModal },
        ]);
        //logout();
      }
    } catch (error) {
      console.log("manually send verification email error:", error);
    }
  }

  const openModal = (title, description, buttons = []) => {
    setModalContent({
      title,
      description,
      buttons,
    });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  async function handleLogout() {
    // setError("");

    try {
      await logout();
    } catch (error) {
      // setError("Failed to logout");
    }
  }
  const handleVerifyEmail = () => {
    openModal(
      "Email Not Verified",
      "Your email is not verified. Please verify your email to continue.",
      [
        // { text: "Close", onClick: closeModal },
        { text: "Cancel", onClick: closeModal },
        {
          text: "Resend Verification Email",
          onClick: () => manuallySendVerificationEmail(currentUser.email),
        },
      ]
    );
  };

  return (
    <>
      <div className="user_main">
        <div className="mob_price">
          <p className="welcome">Welcome, {currentUser.first_name}!</p>
          <p className="price mob-p">
            ${parseInt(currentUser.balance)}.
            <span className="zero">
              {(currentUser.balance % 1).toFixed(2).split(".")[1]}
            </span>
          </p>
        </div>
        <div className="user_1">
          <p className="price p-two">
            ${parseInt(currentUser.balance)}.
            <span className="zero">
              {(currentUser.balance % 1).toFixed(2).split(".")[1]}
            </span>
          </p>

          <Link to={"/user/order-history"}>
            <svg
              width={51}
              height={49}
              viewBox="0 0 51 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect
                y="0.37088"
                width="50.0347"
                height="47.9705"
                rx={8}
                fill="#EAFFF0"
              />
              <path
                d="M30.469 12.4516C34.4396 12.4516 36.6811 14.8507 36.6928 19.0805V30.6964C36.6928 34.9249 34.4396 37.3253 30.469 37.3253H19.5659C15.5953 37.3253 13.3433 34.9249 13.3433 30.6964L13.3433 19.0805C13.3433 14.8507 15.5953 12.4516 19.5659 12.4516L30.469 12.4516ZM25.6018 17.588C25.2737 17.3766 24.8768 17.3766 24.5627 17.588C24.2463 17.7982 24.0724 18.185 24.1062 18.5705L24.1062 31.2437C24.1658 31.7784 24.5849 32.1764 25.0752 32.1764C25.5784 32.1764 25.9975 31.7784 26.0442 31.2437L26.0442 18.5705C26.0909 18.185 25.917 17.7982 25.6018 17.588ZM20.1497 21.6673C19.8344 21.4559 19.4363 21.4559 19.1223 21.6673C18.8059 21.8787 18.6319 22.263 18.667 22.6498L18.667 31.2437C18.7125 31.7784 19.1328 32.1764 19.6348 32.1764C20.138 32.1764 20.5571 31.7784 20.6038 31.2437L20.6038 22.6498C20.64 22.263 20.4637 21.8787 20.1497 21.6673ZM30.9593 26.1819C30.6453 25.9704 30.2483 25.9704 29.9214 26.1819C29.6051 26.3933 29.4311 26.7652 29.4778 27.1644V31.2437C29.5245 31.7784 29.9436 32.1764 30.4468 32.1764C30.9371 32.1764 31.3563 31.7784 31.4158 31.2437V27.1644C31.4497 26.7652 31.2757 26.3933 30.9593 26.1819Z"
                fill="#62C57E"
              />
            </svg>
          </Link>
          <div className="order" onClick={handleLogout}>
            Logout
            <svg
              width={22}
              height={22}
              viewBox="0 0 19 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.49586 1.85498C11.3591 1.85498 12.8787 3.62122 12.8787 5.79574V10.0471L8.29699 10.0471C7.96864 10.0471 7.70901 10.3489 7.70901 10.7306C7.70901 11.1033 7.96864 11.414 8.29699 11.414L12.8787 11.414L12.8787 15.6565C12.8787 17.831 11.3591 19.6061 9.48059 19.6061L5.7618 19.6061C3.89096 19.6061 2.37137 17.8399 2.37137 15.6654L2.37137 5.80461C2.37137 3.62122 3.89859 1.85498 5.76944 1.85498L9.49586 1.85498ZM14.7851 7.66866C15.0103 7.39352 15.378 7.39352 15.6032 7.65979L17.7947 10.2426C17.9073 10.3757 17.9673 10.5443 17.9673 10.7307C17.9673 10.9082 17.9073 11.0858 17.7947 11.21L15.6032 13.7928C15.4906 13.9259 15.3405 13.9969 15.1979 13.9969C15.0478 13.9969 14.8977 13.9259 14.7851 13.7928C14.56 13.5265 14.56 13.0916 14.7851 12.8254L15.986 11.4142L12.8788 11.4142V10.0473L15.986 10.0473L14.7851 8.6361C14.56 8.36983 14.56 7.93493 14.7851 7.66866Z"
                fill="#62c57e"
              />
            </svg>
          </div>
          <NavLink to="/user/profile-update">
            {currentUser.pic ? (
              <img src={currentUser.pic} alt="User" className="userimg" />
            ) : null}
          </NavLink>
        </div>
      </div>
      <CustomModal
        isOpen={modalIsOpen}
        title={modalContent.title}
        description={modalContent.description}
        buttons={modalContent.buttons}
        closeModal={closeModal}
      />
    </>
  );
}

export default UserTopNav;
