import React from 'react'

function NotFoundPage() {
  return (
    <div className="centered-container">
    <div className="login-container">
      <h2>404 Page Not Found</h2>
    </div>
    </div>
  )
}

export default NotFoundPage;