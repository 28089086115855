import React, { useState, useEffect } from "react";

const Notification = ({ message }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return visible ? (
    <div
      style={{
        width: "300px",
        backgroundColor: "#fff",
        textAlign: "center",
        borderRadius: "5px",
        border: '1.5px solid #61dc84',
        padding: "15px",
        
        zIndex: 9999,
        position: "fixed",
        top: "110px",
        right: "15px",
      }}
    >
      <p className="select_1" style={{ color: "#61dc84", marginBottom: 0 }}>{message}</p>
    </div>
  ) : null;
};

export default Notification;
