import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, getDocs } from 'firebase/firestore';
import { prServicesDataCollectionRef, prServicesCatsDataCollectionRef } from '../../../firebase/firestore/collections';

function EditPRServicePage() {
  const [service, setService] = useState({
    name: "",
    category: "", // This will store the category ID
    categoryName: "", // This is to display the category name
    sellingPrice: "",
    status: false,
    url: "",
    traffic: "",
    da: "",
    pa: "",
    region: "",
    dofollow: false,
    sampleUrl: "",
    tat: "",
  });
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const countries = [
    "Global",
    "International",
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor (Timor-Leste)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];
  useEffect(() => {
    const fetchCategories = async () => {
      const querySnapshot = await getDocs(prServicesCatsDataCollectionRef);
      const cats = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setCategories(cats);
    };

    const getServiceDetails = async () => {
      setLoading(true);
      try {
        const serviceDoc = doc(prServicesDataCollectionRef, params.id);
        const docSnap = await getDoc(serviceDoc);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setService({
            id: docSnap.id,
            name: data.name,
            category: data.site_cat,
            categoryName: data.category,
            sellingPrice: data.price,
            status: data.status,
            url: data.url,
            traffic: data.traffic,
            da: data.da,
            pa: data.pa,
            region: data.region || "", // Ensure to have a fallback value if the field might not exist
            dofollow: data.dofollow || false,
            sampleUrl: data.sample_url || "",
            tat: data.tat,
          });
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error getting document:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
    getServiceDetails();
  }, [params.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const serviceDoc = doc(prServicesDataCollectionRef, service.id);
      await updateDoc(serviceDoc, {
        name: service.name,
        site_cat: service.category,
        category:
          categories.find((cat) => cat.id === service.category)?.name || "",
        price: service.sellingPrice,
        status: service.status,
        url: service.url,
        traffic: service.traffic,
        da: service.da,
        pa: service.pa,
        region: service.region,
        dofollow: service.dofollow,
        sample_url: service.sampleUrl,
      });
      navigate("/admin/pr-services");
    } catch (error) {
      console.log("Error updating document:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setService((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-container">
      <h1>Edit PR Service</h1>
      <form onSubmit={handleSubmit} className="pr-service-form">
        <div className="form-group">
          <label htmlFor="name">Publication Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={service.name}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="category">Category:</label>
          <select
            id="category"
            name="category"
            value={service.category}
            onChange={handleChange}
          >
            <option value="">Select a Category</option>
            {categories.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="sellingPrice">Selling Price:</label>
          <input
            type="text"
            id="sellingPrice"
            name="sellingPrice"
            value={service.sellingPrice}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="tat">Turnaround Time (TAT):</label>
          <input
            type="text"
            id="tat"
            name="tat"
            value={service.tat}
            onChange={handleChange}
          />
        </div>

        <div className="form-group form-check">
          <input
            type="checkbox"
            id="status"
            name="status"
            checked={service.status}
            onChange={handleChange}
          />
          <label htmlFor="status">Active Status</label>
        </div>

        <div className="form-group">
          <label htmlFor="url">URL:</label>
          <input
            type="text"
            id="url"
            name="url"
            value={service.url}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="traffic">Traffic (Monthly Visits):</label>
          <input
            type="number"
            id="traffic"
            name="traffic"
            value={service.traffic}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="da">DA (Domain Authority):</label>
          <input
            type="number"
            id="da"
            name="da"
            value={service.da}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="pa">PA (Page Authority):</label>
          <input
            type="number"
            id="pa"
            name="pa"
            value={service.pa}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="region">Region:</label>
          <select
            id="region"
            name="region"
            value={service.region}
            onChange={handleChange}
          >
            <option value="">Select a Region</option>
            {countries.map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group form-check">
          <input
            type="checkbox"
            id="dofollow"
            name="dofollow"
            checked={service.dofollow}
            onChange={handleChange}
          />
          <label htmlFor="dofollow">Dofollow</label>
        </div>

        <div className="form-group">
          <label htmlFor="sampleUrl">Sample URL:</label>
          <input
            type="text"
            id="sampleUrl"
            name="sampleUrl"
            value={service.sampleUrl}
            onChange={handleChange}
          />
        </div>

        <div className="form-actions">
          <button type="submit" className="btn-save" disabled={loading}>
            {loading ? "Updating..." : "Update Service"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditPRServicePage;
