import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { saleDocumentRef } from '../../../firebase/firestore/collections';

function EditSalesPage() {
  const navigate = useNavigate();

  const [percent, setPercent] = useState('');
  const [price, setPrice] = useState('');
  const [sale_price, setSale_price] = useState('');
  const [sale_type, setSale_type] = useState('');
  const [status, setStatus] = useState(false);
  const [publication, setPublication] = useState('');

  useEffect(() => {
    const fetchSaleData = async () => {
      try {
        const saleDoc = await getDoc(saleDocumentRef);

        if (saleDoc.exists()) {
          const saleData = saleDoc.data();
          setPercent(saleData.percent || '');
          setPrice(saleData.price || '');
          setSale_price(saleData.sale_price || '');
          setSale_type(saleData.sale_type || '');
          setStatus(saleData.status || false);
          setPublication(saleData.publication || '');
        } else {
          // Handle case where sale document does not exist
          console.log('Sale document not found.');
        }
      } catch (error) {
        console.error('Error fetching sale data: ', error);
      }
    };

    fetchSaleData();
  }, []); // Empty dependency array to fetch data once when component mounts

  const handleUpdate = async (e) => {
    e.preventDefault();
  
    try {
      // Update the document based on fields from saleDocumentRef
      await setDoc(saleDocumentRef, {
        percent,
        price,
        sale_price,
        sale_type,
        status,
        publication,
      }, { merge: true }); // Use merge: true to only update provided fields
  
      // Display success alert
      window.alert('Sale document updated successfully');
  
      // Redirect to sales page after updating
      navigate('/admin/sale');
    } catch (error) {
      console.error('Error updating sale: ', error);
  
      // Display error alert
      window.alert('Failed to update sale document. Please try again.');
    }
  };
  
  return (
    <div className="page-container">
      <h1>Edit Sale Banner </h1>
      <form onSubmit={handleUpdate} className="user-edit-form">
        {/* Fields for editing sale data */}
        <div className="form-group">
          <label htmlFor="percent" className="form-label">Percent:</label>
          <input
            id="percent"
            type="text"
            value={percent}
            onChange={(e) => setPercent(e.target.value)}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="price" className="form-label">Price:</label>
          <input
            id="price"
            type="text"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="sale_price" className="form-label">Sale Price:</label>
          <input
            id="sale_price"
            type="text"
            value={sale_price}
            onChange={(e) => setSale_price(e.target.value)}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="sale_type" className="form-label">Sale Type:</label>
          <input
            id="sale_type"
            type="text"
            value={sale_type}
            onChange={(e) => setSale_type(e.target.value)}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="publication" className="form-label">Publication:</label>
          <input
            id="publication"
            type="text"
            value={publication}
            onChange={(e) => setPublication(e.target.value)}
            className="form-control"
            required
          />
        </div>

        <div className="form-group form-check">
          <input
            type="checkbox"
            checked={status}
            onChange={(e) => setStatus(e.target.checked)}
            className="form-check-input"
            id="status"
          />
          <label htmlFor="status" className="form-check-label">Active Status</label>
        </div>

        {/* Submit button */}
        <div className="form-actions">
          <button type="submit" className="btn-save">Update Sale</button>
        </div>
      </form>
    </div>
  );
}

export default EditSalesPage;
