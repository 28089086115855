import React from 'react';
import { Helmet } from 'react-helmet';

function DynamicPage({ pageDetails }) {
  return (
    <div className='card_container'>
      <Helmet>
        <title className='title'>{pageDetails?.title}</title>
        <meta property="og:title" content={pageDetails?.title} />
      </Helmet>
      <h2>{pageDetails?.title}</h2>
      <div className='select_1' dangerouslySetInnerHTML={{ __html: pageDetails?.content }} />
    </div>
  );
}

export default DynamicPage;
