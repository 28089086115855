import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs, addDoc, serverTimestamp, orderBy, updateDoc, doc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from 'react-router-dom';
import { getLocalApiKeys } from "../../utils/localStorage/apiKeys";
import { getLocalApiLinks } from "../../utils/localStorage/apiLinks";

function SupportPage() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const [showForm, setShowForm] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [newTicket, setNewTicket] = useState({
    subject: "",
    message: "",
    relatedToOrder: false,
    orderId: "",
  });
  const [userOrders, setUserOrders] = useState([]);
  const [apis, setApis] = useState({});
  const [apiKeys, setApiKeys] = useState({});

  useEffect(() => {
    const fetchApis = async () => {
      try {
        const apiLinks = await getLocalApiLinks();

        setApis(apiLinks);
      } catch (error) {
        console.error("Error fetching api links:", error);
      }
    };

    const fetchApiKeys = async () => {
      try {
        const apiKeys = await getLocalApiKeys();

        setApiKeys(apiKeys);
      } catch (error) {
        console.error("Error fetching api keys:", error);
      }
    };

    fetchApis();
    fetchApiKeys();
  }, []);

  const sendEmailNotification = async (ticketId) => {
    const apiEndpoint = apis.stripe_premail;
    const payload = {
      apikey: apiKeys.stripe_premail,
      type: "new_ticket",
      ticketId: ticketId,
      userEmail: currentUser.email,
      userName: currentUser.first_name,
    };

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Email notification failed to send.");
      }

      // Handle the response from your PHP script
      const responseData = await response.text();
    } catch (error) {
      console.log("Failed to send email notification:", error);
    }
  };

  useEffect(() => {
    if (!currentUser) return;

    const fetchUserOrders = async () => {
      const ordersCollectionRef = collection(db, "orders");
      const q = query(
        ordersCollectionRef,
        where("user", "==", currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      setUserOrders(
        querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
      );
    };

    fetchUserOrders();
    fetchTickets();
  }, [currentUser]);

  const fetchTickets = async () => {
    const ticketsCollectionRef = collection(db, "tickets");
    const q = query(
      ticketsCollectionRef,
      where("createdBy", "==", currentUser.uid),
      orderBy("createdAt", "desc")
    );
    const querySnapshot = await getDocs(q);
    let fetchedTickets = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      createdAt: doc.data().createdAt?.toDate().toLocaleString(),
    }));
    setTickets(fetchedTickets);
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setNewTicket((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentUser) return;

    try {
      const docRef = await addDoc(collection(db, "tickets"), {
        ...newTicket,
        createdBy: currentUser.uid,
        createdAt: serverTimestamp(),
        status: "open",
        user_first_name: currentUser.first_name,
        user_last_name: currentUser.last_name,
        user_email: currentUser.email,
      });

      // Reset form, hide form, fetch tickets again
      setNewTicket({
        subject: "",
        message: "",
        relatedToOrder: false,
        orderId: "",
      });
      setShowForm(false);
      fetchTickets();

      // Send an email notification
      await sendEmailNotification(docRef.id);
    } catch (error) {
      console.log("Error adding document: ", error);
    }
  };

  const handleViewTicket = (ticketId) => {
    navigate(`/user/support/ticket/${ticketId}`);
  };

  const handleCloseTicket = async (ticketId) => {
    const ticketRef = doc(db, "tickets", ticketId);
    await updateDoc(ticketRef, { status: "closed" });
    fetchTickets();
  };

  return (
    <div className="content">
      <button
        className="paybtn contain-width card_container"
        onClick={() => setShowForm(!showForm)}
      >
        {showForm ? "Cancel" : "Create a Ticket"}
      </button>

      {showForm && (
        <form onSubmit={handleSubmit} className="ticket-form">
          <h1 className="title">Create a ticket </h1>
          <div className="form-field">
            <input
              type="text"
              name="subject"
              value={newTicket.subject}
              onChange={handleInputChange}
              placeholder="Subject"
              required
              className="form-input"
            />
          </div>
          <div className="form-field">
            <textarea
              name="message"
              value={newTicket.message}
              onChange={handleInputChange}
              placeholder="Message"
              required
              className="form-textarea"
            ></textarea>
          </div>
          <div className="form-field checkbox-field">
            <label className="form-checkbox-label check_2">
              <input
                type="checkbox"
                name="relatedToOrder"
                checked={newTicket.relatedToOrder}
                onChange={handleInputChange}
                className="form-checkbox"
              />
              Related to an order
            </label>
          </div>
          {newTicket.relatedToOrder && (
            <div className="form-field">
              <select
                name="orderId"
                value={newTicket.orderId}
                onChange={handleInputChange}
                required
                className="form-select"
              >
                <option value="">Select an order</option>
                {userOrders.map((order) => (
                  <option key={order.id} value={order.id}>
                    {" "}
                    {order.id || "Unnamed Order"}
                  </option> // Ensure 'title' is correct
                ))}
              </select>
            </div>
          )}
          <div className="form-actions">
            <button type="submit" className="paybtn">
              Submit Ticket
            </button>
          </div>
        </form>
      )}
      <br />
      <br />
      <div className="t_main">
        <div className="co_main">
          <table className="table_main">
            <thead>
              <tr className="onetr">
                <th className="od">Subject</th>
                <th className="od">Status</th>
                <th className="od">Created At</th>
                <th className="od">Actions</th>
              </tr>
            </thead>
            <tbody>
              {!tickets.length ? (
                <tr className="s_tdp">
                  <td className="tdp" colSpan={"4"}>
                    No data available
                  </td>
                </tr>
              ) : null}
              {tickets.map((ticket, index) => (
                <tr key={ticket.id} className={index % 2 === 0 ? "s_tdp" : ""}>
                  <td className="tdp">{ticket.subject}</td>
                  <td className="tdp">{ticket.status}</td>
                  <td className="tdp">{ticket.createdAt}</td>
                  <td
                    className="tdp"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <button onClick={() => handleViewTicket(ticket.id)}>
                      View
                    </button>
                    {ticket.status !== "closed" && (
                      <button
                        style={{ marginLeft: 4 }}
                        onClick={() => handleCloseTicket(ticket.id)}
                      >
                        Close
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SupportPage;
