import React, { useEffect, useState } from 'react'
import ClipLoader from "react-spinners/ClipLoader";
import Notification from '../../components/Notification';
import CustomModal from '../../components/modals/CustomModal';

import { doc, updateDoc, query, where, getDocs, addDoc, increment, orderBy  } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { getLocalApiKeys } from '../../utils/localStorage/apiKeys';
import { getLocalApiLinks } from '../../utils/localStorage/apiLinks';
import { usersCollectionRef, transactionsCollectionRef } from '../../firebase/firestore/collections';

import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';


function UserTransactions({ loading, transactions }) {



  if (loading) {
    return (
      <div className="t_main">
        <div className="co_main">
          <table className="table_main">
            <tbody>
              <tr>
                <td>Loading transactions...</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  if (!transactions.length > 0) {
    return (
      <div className="t_main">
        <div className="co_main">
          <table className="table_main">
          <thead>
            <tr className="onetr">
              <th className="od">ID</th>
              <th className="od">Date</th>
              <th className="od">Description</th>
              <th className="od">Amount</th>
              <th className="od">Status</th>
            </tr>
          </thead>
            <tbody>
              <tr>
                <td className='od'>No transactions found</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div className="t_main">
      <div className="co_main">
        <table className="table_main">
          <thead>
            <tr className="onetr">
              <th className="od">ID</th>
              <th className="od">Date</th>
              <th className="od">Description</th>
              <th className="od">Amount</th>
              <th className="od">Status</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr
                key={transaction.id}
                className={index % 2 === 0 ? "s_tdp" : ""}
              >
                <td className="tdp">{transaction.id}</td>
                <td className="tdp">{transaction.date}</td>
                <td className="tdp">{transaction.description}</td>
                <td className="tdp">${transaction.amount}</td>
                <td className="tdp">
                  <p
                    className={
                      transaction.status === "In-Progress"
                        ? "tdp_stat"
                        : transaction.status === "Pending"
                        ? "pending"
                        : transaction.status === "Cancelled" ||
                          transaction.status === "Refunded"
                        ? "cancelled"
                        : "tdp_stat"
                    }
                    data-label="Status"
                  >
                    {transaction.status}
                  </p>
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function ManageCards() {
  const stripe = useStripe();
  const elements = useElements();

  const { currentUser, updateCurrentUserData } = useAuth();

  const [transactions, setTransactions] = useState([]);
  const [addFundsAmount, setAddFundsAmount] = useState("");
  const [addFundsLoading, setAddFundsLoading] = useState(false);
  const [fundsError, setFundsError] = useState("");

  const [loading, setLoading] = useState(false);
  const [savedCards, setSavedCards] = useState([]);
  const [createNewCard, setCreateNewCard] = useState(false);
  const [addCardLoading, setAddCardLoading] = useState(false);
  const [cardsLoading, setCardsLoading] = useState(false);

  const [apis, setApis] = useState({});
  const [apiKeys, setApiKeys] = useState({});
  const [notification, setNotification] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    description: "",
    buttons: [],
  });

  useEffect(() => {
    const fetchApis = async () => {
      try {
        const apiLinks = await getLocalApiLinks();

        setApis(apiLinks);
      } catch (error) {
        console.error("Error fetching api links:", error);
      }
    };

    const fetchApiKeys = async () => {
      try {
        const apiKeys = await getLocalApiKeys();

        setApiKeys(apiKeys);
      } catch (error) {
        console.error("Error fetching api keys:", error);
      }
    };

    fetchApis();
    fetchApiKeys();
    fetchUserTransactions();
  }, []);

  useEffect(() => {
    if (Object.keys(apis).length === 0) return;
    getSavedCards();
  }, [apis]);

  useEffect(() => {
    // Clear notification after 3 seconds
    const timer = setTimeout(() => {
      setNotification("");
    }, 5000);
    return () => clearTimeout(timer);
  }, [notification]);

  const openModal = (title, description, buttons = []) => {
    setModalContent({
      title,
      description,
      buttons,
    });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const getSavedCards = async () => {
    try {
      if (!currentUser?.customer_id) {
        console.log("No customer ID found");
        // Show a message to the user here if needed
        return;
      }

      const response = await fetch(apis.stripe_customer_cards, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          apikey: apiKeys.stripe_subscription,
          action: "get-cards",
          customer_id: currentUser?.customer_id,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch cards. Status: ${response.status}`);
      }

      const responseData = await response.json();
      // console.log("User cards response", responseData);

      if (responseData.cards) {
        setSavedCards(responseData.cards);
      }
    } catch (err) {
      console.error("Error fetching saved cards:", err.message);
      // Handle the error, e.g., display an error message to the user
    }
  };

  const fetchUserTransactions = async () => {
    try {
      setLoading(true);

      const userTransactionsQuery = query(
        transactionsCollectionRef,
        where("userid", "==", currentUser.uid),
        orderBy("date", "desc")
      );

      const querySnapshot = await getDocs(userTransactionsQuery);
      // console.log("Query Snapshot:", querySnapshot);

      const fetchedTransactions = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
          date: data.date.toDate().toISOString().split("T")[0],
        };
      });
      // console.log("Fetched Transactions:", fetchedTransactions);
      setTransactions(fetchedTransactions);
    } catch (error) {
      console.error("Error fetching user transactions: ", error);
    } finally {
      setLoading(false);
    }
  };

  const updateUserData = async (customerId) => {
    try {
      const userDocRef = doc(usersCollectionRef, currentUser.uid);
      let data = { customer_id: customerId };

      updateCurrentUserData({ customer_id: customerId });
      await updateDoc(userDocRef, data);
    } catch (error) {
      console.log("error updating user's customer_id", error);
    }
  };

  const createStripeCustomer = async () => {
    try {
      const body = {
        name: currentUser.first_name + " " + currentUser.last_name,
        email: currentUser.email,
        user_id: currentUser.uid,
      };
      //  console.log(JSON.stringify(body));

      let response = await fetch(apis.stripe_create_customer, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      response = await response.json();
      //  console.log("create customer", response);
      return response;
    } catch (error) {
      console.log("error creating customer", error);
    }
  };

  const addTransactionToCollection = async (
    amountPaid,
    currency,
    paymentMethodId
  ) => {
    try {
      const transactionData = {
        amount: amountPaid / 100,
        currency: currency,
        date: new Date(),
        method: "Stripe",
        paymentID: paymentMethodId,
        status: "Completed",
        userid: currentUser.uid,
        username: currentUser.username,
        description: "Add funds to account",
      };

      await addDoc(transactionsCollectionRef, transactionData);
    } catch (error) {
      setAddFundsLoading(false);
      console.log("error updating transactions", error);
    }
  };

  const updateUserBalance = async () => {
    try {
      const userDocRef = doc(usersCollectionRef, currentUser.uid);

      // If any profile details have been updated, then update them in Firestore
      await updateDoc(userDocRef, {
        balance: increment(addFundsAmount),
      });

      updateCurrentUserData({
        balance: parseFloat(currentUser.balance) + parseFloat(addFundsAmount),
      });
    } catch (error) {
      console.log("update user balance failed", error);
    }
  };

  const handleAddFunds = async () => {
    setFundsError("");
    if (savedCards.length === 0) {
      setFundsError(
        "Please ensure you have at least one card saved to add funds"
      );
      return;
    }

    if (
      addFundsAmount === "" ||
      !addFundsAmount ||
      addFundsAmount < 5 ||
      addFundsAmount > 1000
    ) {
      setFundsError(
        "Please enter an amount between the specified range (only the number)"
      );
      return;
    }

    try {
      setAddFundsLoading(true);
      setModalContent({
        title: "",
        description: "",
        buttons: [],
      });

      const defaultCard = savedCards.find((card) => card.isDefault);

      const body = {
        apikey: apiKeys.process_payment,
        paymentMethodId: defaultCard.paymentMethodId,
        amount: addFundsAmount * 100,
        currency: "usd",
        description: "add funds",
        user_id: currentUser.uid,
        name: currentUser.first_name + " " + currentUser.last_name,
        customer_id: currentUser.customer_id,
        email: currentUser.email,
      };

      // console.log("process payment body:", JSON.stringify(body));
      let response = await fetch(apis.stripe_add_funds, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      response = await response.json();

      if (response.error) {
        alert(response.message);
        return;
      } else {
        // console.log("process payment response", response);
        openModal(
          "Funds Added!",
          "Successfully added funds to your account balance",
          [{ text: "Close", onClick: closeModal }]
        );
        addTransactionToCollection(
          body.amount,
          response.data.currency,
          response.data.id
        );
        fetchUserTransactions();
        updateUserBalance();
        setAddFundsAmount("");
      }
    } catch (error) {
      setFundsError("Failed to add funds");
      console.log("Funds error", error);
    } finally {
      setAddFundsLoading(false);
    }
  };

  const addNewCard = async (e) => {
    e.preventDefault();

    try {
      let customerId = currentUser.customer_id;

      if (!currentUser.customer_id || currentUser.customer_id.trim() === "") {
        const response = await createStripeCustomer();
        if (response.error) {
          alert(response.message);
          return;
        } else {
          updateUserData(response.data.customer_id);
          customerId = response.data.customer_id;
        }
      }

      const { token, error } = await stripe.createToken(
        elements.getElement(CardElement)
      );

      let body = {
        apikey: apiKeys.stripe_subscription,
        action: "add-card",
        token: token.id,
        customer_id: customerId,
        name: `${currentUser.first_name} ${currentUser.last_name}`,
        email: currentUser.email,
        user_id: currentUser.uid,
      };

      // console.log("body", JSON.stringify(body));
      let response = await fetch(apis.stripe_customer_cards, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      response = await response.json();
      //  console.log("add card response", response);

      if (response.status === "success") {
        elements.getElement(CardElement).clear();
        getSavedCards();
        setNotification("Card added successfully!");
      }
    } catch (err) {
      console.log("Failed to add card:", err);
      setNotification("Failed to add card. Please try again.");
    }
  };

  const removeCard = async (paymentMethodId) => {
    try {
      const body = {
        apikey: apiKeys.stripe_subscription,
        action: "remove-card",
        paymentMethodId: paymentMethodId,
      };
      let response = await fetch(apis.stripe_remove_card, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      response = await response.json();
      //   console.log("remove card response:", response);

      getSavedCards();
      setNotification("Card removed successfully!");
    } catch (err) {
      console.log("Failed to remove card:", err.message);
      setNotification("Failed to remove card. Please try again.");
    }
  };

  const makeCardDefault = async (paymentMethodId) => {
    try {
      const body = {
        apikey: apiKeys.stripe_subscription,
        action: "set-default-payment-method",
        customerId: currentUser.customer_id,
        paymentMethodId: paymentMethodId,
      };

      console.log("body:", body);
      let response = await fetch(apis.stripe_default_payment_method, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      response = await response.json();

      getSavedCards();
      setNotification("Card set as default successfully!");
    } catch (err) {
      console.log("Failed to set card as default:", err);
      setNotification("Failed to set card as default. Please try again.");
    }
  };

  return (
    <>
      <div className="content">
        <div>
          <div className="card_container">
            <h1 className="title">Manage Payment Metods</h1>
            <div>
              <button

                onClick={() => setCreateNewCard((prev) => !prev)}
              >
                Add Card
              </button>
              <br />
              <br />
            </div>
            <div>
              {createNewCard ? (
                <form onSubmit={addNewCard}>
                  <div className="form-group">
                    <label htmlFor="">Enter Card Information</label>
                    <CardElement className="CardElement" />
                  </div>
                  <button type="submit">
                    Add
                  </button>
                  <br />
                  <br />
                </form>
              ) : null}
            </div>

            {savedCards.length > 0 ? (
              <>
                <p className="select_1">Saved Payment Methods</p>
                <div className="t_main">
                  <div className="co_main">
                    <table className="table_main">
                      <thead>
                        <tr className="onetr">
                          <th className="od">Brand</th>
                          <th className="od">Card Number</th>
                          <th className="od">Expiry Date</th>
                          <th className="od">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {savedCards.map((card, index) => (
                          <tr
                            key={card.paymentMethodId}
                            className={index % 2 === 0 ? "s_tdp" : ""}
                          >
                            <td className="tdp">{card.brand}</td>
                            <td className="tdp">
                              **** **** **** {card.last4}{" "}
                              {card.isDefault && "(Default)"}
                            </td>
                            <td className="tdp">{`${card.exp_month}/${card.exp_year}`}</td>
                            <td className="tdp">
                              <button
                                type="button"
                                onClick={() => removeCard(card.paymentMethodId)}
                              >
                                Remove
                              </button>{" "}
                              {!card.isDefault && (
                                <button
                                  type="button"
                                  onClick={() =>
                                    makeCardDefault(card.paymentMethodId)
                                  }
                                >
                                  Default
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            ) : (
              <p>{/* No Data Available */}</p>
            )}
          </div>
        </div>
        <br />

        <div className="add-funds card_container">
          <h1 className="title">Add Funds</h1>

          {/* <p>Current Balance: ${currentUser.balance}</p> */}
          <div className="profile-details">
            <label className="select_1">
              Add funds via saved default payment method
            </label>
            <input
              type="number"
              placeholder="Amount (5-1000)"
              value={addFundsAmount}
              onChange={(e) => setAddFundsAmount(e.target.value)}
              min={5}
              max={1000}
            />
            <br />
            {fundsError !== "" ? (
              <p style={{ color: "red", fontSize: "14px" }}>{fundsError}</p>
            ) : null}
            <button
              className="paybtn"
              disabled={addFundsLoading}
              onClick={handleAddFunds}
            >
              {addFundsLoading ? (
                <ClipLoader
                  color="#f5f5f5"
                  loading={addFundsLoading}
                  size={15}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                "Add Funds"
              )}
            </button>
          </div>
        </div>
        <br />

        <div className="user-transactions card_container">
          <h1 className="title">Transactions</h1>
          <br />
          <UserTransactions loading={loading} transactions={transactions} />
        </div>
      </div>

      {notification && notification.trim() !== "" ? (
        <Notification message={notification} />
      ) : null}

      <CustomModal
        isOpen={modalIsOpen}
        title={modalContent.title}
        description={modalContent.description}
        buttons={modalContent.buttons}
        closeModal={closeModal}
      />
    </>
  );
}

const ManageCardsPage = () => {
  const [apiKeys, setApiKeys] = useState({
    stripe_publishable_key: "",
  });

  const stripePromise = loadStripe(apiKeys.stripe_publishable_key);

  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        const apiKeys = await getLocalApiKeys();

        setApiKeys(apiKeys);
      } catch (error) {
        console.error("Error fetching api keys:", error);
      }
    };

    fetchApiKeys();
  }, []);

  return (
    apiKeys.stripe_publishable_key !== "" && (
      <Elements stripe={stripePromise}>
        <ManageCards />
      </Elements>
    )
  );
};

export default ManageCardsPage
