import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const CustomModal = ({ isOpen, title, description, buttons, closeModal }) => {
  const renderButtons = () => {
    return (
      <div style={{ display: "flex", gap: "3px", justifyContent: "flex-end" }}>
        {buttons.map((button, index) => (
          <button key={index} onClick={button.onClick}>
            {button.text}
          </button>
        ))}
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <h2>{title}</h2>
      {description && <p className="select_1">{description}</p>}
      {renderButtons()}
    </Modal>
  );
};

export default CustomModal;
