import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function UpdatePage() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const docRef = doc(db, 'pages', params.id);
        const docSnap = await getDoc(docRef);
          console.log(params.id)
        if (docSnap.exists()) {
          const pageData = docSnap.data();
          setTitle(pageData.title);
          setContent(pageData.content);
          setDisplay(pageData.display);
        } else {
          console.log('No such document!');
          navigate('/admin/manage-pages');
        }
      } catch (error) {
        console.log('Error fetching page data:', error);
      } finally {
        setLoading(false)
      }
    };

    fetchPageData();
  }, [params.id, navigate]);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleDisplayChange = (event) => {
    setDisplay(event.target.checked);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const docRef = doc(db, 'pages', params.id);
      await updateDoc(docRef, {
        title: title,
        content: content,
        display: display,
      });
      navigate('/admin/manage-pages');
    } catch (error) {
      console.log('Error updating page:', error);
    }
  };

  // The styles are defined here
  const inputStyle = {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  };

  const buttonStyle = {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#5cb85c',
    color: '#fff',
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '10px',
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-container">
      <h2>Update Page</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={handleTitleChange}
            placeholder="Page Title"
            required
            style={inputStyle} // Applied the same styles as in AddPage
          />
        </div>
        <div>
          <label htmlFor="content">Content:</label>
          <ReactQuill theme="snow" value={content} onChange={handleContentChange} />
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={display}
              onChange={handleDisplayChange}
            /> Display Page
          </label>
        </div>
        <button type="submit" style={buttonStyle}>Update Page</button> {/* Applied the button styles */}
      </form>
    </div>
  );
}

export default UpdatePage;
